import { useEffect } from "react";
import DocumentDisplay from "../../components/DocumentDisplay";

export default function PrivacyPolicy() {
    useEffect(() => {
        document.title = "Политика обработки персональных данных";
    }, []);
    return (    
        <div>
            <DocumentDisplay file="/documents/Политика обработки персональных данных.pdf" />
        </div>
    )
}