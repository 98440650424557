import { AxiosResponse } from "axios"
import { $anon_api } from "../http"
import { Language } from "../models/Resume"
import { University } from "../models/Education"

export interface WorkFormat {
    id: number | null
    name: string | null
}

export interface City {
    id: number | null;
    name: string | null;
    area: string | null;
    country: string | null;
}

export interface Direction{
    id: number | null,
    name: string | null
}

export default class GetGeneralInformationService {
    static async GetWorkFormats(): Promise<AxiosResponse<WorkFormat[]>> {
        return await $anon_api.get('/general/work_format')
    }

    static async GetLanguages(): Promise<AxiosResponse<Language[]>> {
        return await $anon_api.get('/general/languages')
    }

    static async GetCitys(name?: string, page?: number, quantity?: number): Promise<AxiosResponse<City[]>> {
        const params = {
            page: page,
            quantity: quantity,
            ...(name && { name }) // Добавляем параметр name, если он передан
        };
        
        return await $anon_api.get('/general/city', { params });
    }

    static async GetUniversitys(name?: string, page?: number, quantity?: number): Promise<AxiosResponse<University[]>> {
        const params = {
            page: page,
            quantity: quantity,
            ...(name && { name }) // Добавляем параметр name, если он передан
        };
        
        return await $anon_api.get('/general/university', { params });
    }

    static async GetDirections(): Promise<AxiosResponse<Direction[]>> {
        return await $anon_api.get('/general/direction')
    }
}
