import { Link, useNavigate } from "react-router-dom";
import { CSSProperties, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import GetGeneralInformationService, { Direction, WorkFormat } from "../../../api/services/GetGeneralInformationService";
import VacancyService from "../../../api/services/VacancyService";
import { Box } from "../../../components/Box";
import CitySelector from "../../../components/CitySelector";
import CustomSelect from "../../../components/CustomSelect";
import DirectionSelector from "../../../components/DirectionSelector";
import NotificationModal from "../../../components/NotificationModal";

export function AddVacancy() {
    useEffect(() => {
        document.title = "Добавление вакансии";
    }, []);
    const navigate = useNavigate();

    const [secondStep, setSecondStep] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const [workFormats, setWorkFormats] = useState<WorkFormat[]>([]);
    const [directions, setDirections] = useState<Direction[]>([]);
    const [isFromChecked, setIsFromChecked] = useState(false);
    const [isToChecked, setIsToChecked] = useState(false);

    useEffect(() => {
        const fetchWorkFormats = async () => {
            try {
                const response = await GetGeneralInformationService.GetWorkFormats();
                setWorkFormats(response.data);
            } catch (error) {
                // console.error("Ошибка при загрузке форматов работы:", error);
            }
        };

        const fetchDirections = async () => {
            try {
                const response = await GetGeneralInformationService.GetDirections();
                setDirections(response.data);
            } catch (error) {
                // console.error("Ошибка при загрузке направлений:", error);
            }
        };
        fetchWorkFormats();
        fetchDirections();
    }, []);
    
    const optionsResumeSkills = [
        { value: 'C#', label: 'C#' },
        { value: 'Python', label: 'Python' },
        { value: 'ЛитрBalls', label: 'ЛитрBalls' },
    ];

    const handleSkillsChange = (selectedValues: string | string[]) => {
        const newSkills = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
        setSkills(newSkills);
    };

    const [name, setName] = useState<string>("");
    const [cityId, setCityId] = useState<number | null>();
    const [workFormat, setWorkFormat] = useState<number>(1);
    const [directionId, setDirectionId] = useState<number | null>();
    const [desc, setDesc] = useState<string>("");
    const [requirements, setRequirements] = useState<string>("");
    const [skills, setSkills] = useState<string[]>([]);
    const [salaryFrom, setSalaryFrom] = useState<number | null>(0);
    const [salaryTo, setSalaryTo] = useState<number | null>(0);
    const [errorServer, setErrorServer] = useState<string | undefined>(undefined);
    const [nameError, setNameError] = useState<string | null>(null);


    const handleSalaryFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isFromChecked) {
            const value = event.target.value ? parseFloat(event.target.value) : null;
            setSalaryFrom(value);
        }
    };

    const handleSalaryToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isToChecked) {
            const value = event.target.value ? parseFloat(event.target.value) : null;
            setSalaryTo(value);
        }
    };

    const handleFromCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFromChecked(event.target.checked);
        if (!event.target.checked) {
            setSalaryFrom(0); // Сброс значения, если чекбокс не отмечен
        }
    };

    const handleToCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsToChecked(event.target.checked);
        if (!event.target.checked) {
            setSalaryTo(0); // Сброс значения, если чекбокс не отмечен
        }
    };

    const PostCreateVacancy = async () => {
        if(name.length < 5){
            setNameError("Название должно содержать не менее 5 символов");
            return;
        }
        if(!cityId || !name || !desc || !requirements || !skills) {
            setError("Пожалуйста, заполните поле");
            return;
        }
        try {
            const response = await VacancyService.PostCreateVacancy(cityId ?? 0, workFormat ?? 0, directionId ?? 0, name, desc, requirements, skills, salaryFrom ?? null, salaryTo ?? null);
            //  console.log(response);
            setError(undefined);
            setNameError(null);
            navigate("/companyVacancys");
        } catch (error: any) {
            if (error?.response?.data?.detail) {
                setErrorServer(error.response.data.detail);

            } else {
                setErrorServer("Произошла ошибка. Пожалуйста, попробуйте позже.");
            }
        }
    };
    useEffect(() => {
        if (errorServer) {
            const timer = setTimeout(() => {
                setErrorServer(undefined);
            }, 10000); // 10 секунд

            return () => clearTimeout(timer); // Очистка таймера при размонтировании
        }
    }, [errorServer]);

    return(
        <>
            {errorServer && (
                <NotificationModal 
                    message={errorServer} 
                    onClose={() => setErrorServer(undefined)} 
                    type="notification"
                />
            )}
            <Box styleClass="bg-white mb-4">
                <div className="mb-4">
                    <Link to="/companyVacancys" className=" text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться без сохранения</Link>
                    <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                        Добавление новой вакансии
                        <hr className="m-0"></hr>
                    </p>
                </div>
                <div className="row mb-4">
                    <div className="d-flex justify-content-center">
                        <div className="col-md-8 d-flex justify-content-center border flex-wrap" style={{borderRadius:"0.375rem"}}>
                            <div className="d-flex justify-content-center col align-items-center p-2" style={{borderRight:"1px solid gray", cursor:"pointer"}} onClick={() => setSecondStep(false)}>
                                <img src="/images/icons/vacancy/step-1.png" className="me-3" style={{width:"56px", height:"56px"}}></img>
                                <div className="justify-content-center">
                                    <div className="row">
                                        <span className="d-flex justify-content-start " style={{color:"#4640DE"}}>Шаг 1/2</span>
                                    </div>
                                    <div className="row">
                                        <span className="d-flex justify-content-start fw-bold">Основная часть</span>
                                    </div>
                                    <div className="row">
                                        {(!cityId || !name || !skills || skills.length === 0) && error && <span className="text-danger">Есть незаполненные поля</span>}
                                        {nameError && name.length <5 && <span className="text-danger">Ошибка в названии</span>}
                                    </div>
                                </div>
                            </div>

                            {
                                !secondStep ?
                                (<div className="d-flex justify-content-center col align-items-center p-2" style={{cursor:"pointer"}} onClick={() => setSecondStep(true)}>
                                    <img src="/images/icons/vacancy/step-2-disabled.png" className="me-3" style={{width:"56px", height:"56px"}}></img>
                                    <div className="justify-content-center">
                                        <div className="row">
                                            <span className="d-flex justify-content-start " style={{color:"#A8ADB7"}}>Шаг 2/2</span>
                                        </div>
                                        <div className="row">
                                            <span className="d-flex justify-content-start fw-bold" style={{color:"#7C8493"}}>Описание</span>
                                        </div>
                                        <div className="row">
                                            {(!desc || !requirements) && error && <span className="text-danger">Есть незаполненные поля</span>}
                                        </div>
                                    </div>
                                </div>)
                                :
                                (<div className="d-flex justify-content-center col align-items-center p-2">
                                    <img src="/images/icons/vacancy/step-2-active.png" className="me-3" style={{width:"56px", height:"56px"}}></img>
                                    <div className="justify-content-center">
                                        <div className="row">
                                            <span className="d-flex justify-content-start " style={{color:"#4640DE"}}>Шаг 2/2</span>
                                        </div>
                                        <div className="row">
                                            <span className="d-flex justify-content-start fw-bold">Описание</span>
                                        </div>
                                        <div className="row">
                                            {(!desc || !requirements) && error && <span className="text-danger">Есть незаполненные поля</span>}
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                    {/* <div className="d-flex justify-content-center">
                        {errorServer !== "" && <span className="text-danger">{errorServer}</span>}
                    </div> */}
                </div>

                {!secondStep ?
                    (<>
                        <div className="row d-flex justify-content-start mb-3">
                            <h4 className="fw-bold">Основная информация</h4>
                            <hr className="w-100 mt-3"></hr>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Название вакансии</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray">Названия должно описывать одну должность</p>
                                    {!name && <span className="text-danger">{error}</span>}
                                    {nameError && name.length < 5 && <span className="text-danger">{nameError}</span>}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100">
                                        <div className="row d-flex">
                                            <Form.Control
                                                maxLength={80}
                                                minLength={5}
                                                type="text"
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder="например, Бухгалтер"
                                                value={name}
                                                className="bg-white"
                                            />
                                        </div>
                                        <span className="text-color-gray" style={{fontSize:"12px"}}>Не менее 5 и не более 80 символов</span>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Город</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray">Расположение</p>
                                    {!cityId && <span className="text-danger">{error}</span>}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100">
                                        <div className="row d-flex">
                                            <CitySelector onCitySelect={setCityId} styleClass="p-0" initialCityId={cityId}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Тип занятости</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray">Выберите тип занятости</p>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100">
                                        <div className="row d-flex">
                                            {workFormats && workFormats.map((format) => (
                                                <div className="form-check" key={format.id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="workFormat"
                                                        id={`flexRadioDefault${format.id}`}
                                                        value={format.id ?? 1}
                                                        checked={workFormat === format.id}
                                                        onChange={(e) => setWorkFormat(Number(e.target.value))}
                                                    />
                                                    <label className="form-check-label mb-2" htmlFor={`flexRadioDefault${format.id}`} style={{fontSize:"16px"}}>
                                                        {format.name}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Зарплата</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray mb-1">Пожалуйста, укажите предполагаемый диапазон заработной платы для данной должности.</p>
                                    <p style={{fontSize:"14px"}} className="text-color-gray">*Вы можете оставить это поле пустым</p>
                                </div>
                            </div>
                            <div className="col-md-8 d-flex justify-content-center align-items-center">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="row d-flex justify-content-center align-items-center ">
                                        <div className="input-group col me-2">
                                            <div className="input-group-text">
                                                <input  className="form-check-input"
                                                    type="checkbox"
                                                    checked={isFromChecked}
                                                    onChange={handleFromCheckboxChange}
                                                aria-label="Checkbox for following text input"/>
                                            </div>
                                            <input type="number"
                                                className="form-control bg-white"
                                                defaultValue={10000}
                                                value={isFromChecked ? (salaryFrom !== null ? salaryFrom : '') : ''}
                                                onChange={handleSalaryFromChange}
                                                aria-label="Text input with checkbox"
                                                disabled={!isFromChecked} // Отключаем поле, если чекбокс не отмечен
                                            />
                                        </div>
                                        <div className="col d-flex justify-content-center align-items-center "> по </div>
                                        <div className="input-group col">
                                            <div className="input-group-text">
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    checked={isToChecked}
                                                    onChange={handleToCheckboxChange}
                                                    aria-label="Checkbox for following text input"
                                                />
                                            </div>
                                            <input 
                                                defaultValue={20000}
                                                type="number"
                                                className="form-control bg-white"
                                                value={isToChecked ? (salaryTo !== null ? salaryTo : '') : ''}
                                                onChange={handleSalaryToChange}
                                                aria-label="Text input with checkbox"
                                                disabled={!isToChecked} // Отключаем поле, если чекбокс не отмечен
                                             />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Направление</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray mb-1">Выберите направление</p>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100"> 
                                        <div className="row d-flex">
                                            <DirectionSelector onDirectionSelect={setDirectionId} styleClass="p-0" initialDirectionId={directionId}/>
                                             {/* <select
                                                className="form-select"
                                                value={directionId ?? 1}
                                                aria-label="direction"
                                                aria-describedby="direction"
                                                onChange={(e) => {
                                                    const value = e.target.value === '' ? null : Number(e.target.value);
                                                    setDirectionId(value);
                                                }}>
                                                {directions.map(direction => (
                                                    <option key={direction.id} value={direction.id ?? 1}>
                                                        {direction.name}
                                                    </option>
                                                ))}
                                            </select> 
                                             */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>     

                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Требуемые навыки</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray mb-1">Добавьте необходимые навыки для работы</p>
                                    {skills.length === 0 && <span className="text-danger">{error}</span>}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100">
                                        <div className="row d-flex">
                                        <CustomSelect
                                                styleClass="p-0"
                                                initialSelectedValues={skills ?? []}
                                                isMulti={true}
                                                placeholder="Впишите навыки"
                                                // initialOptions={optionsResumeSkills}
                                                onChange={handleSkillsChange}
                                            />
                                            {/* <CustomSelect
                                                styleClass="p-0"
                                                initialSelectedValues={skills ?? []}
                                                isMulti={true}
                                                initialOptions={optionsResumeSkills}
                                                onChange={handleSkillsChange}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>   

                        <div className="d-flex justify-content-end mb-3">
                            <button className="btn-violet large" style={{borderRadius:"0.375rem"}} onClick={(()=> setSecondStep(true))}>Следующий шаг</button>
                        </div>
                    </>)
                    :
                    (<>
                        <div className="row d-flex justify-content-start mb-3">
                            <h4 className="fw-bold">Детали</h4>
                            <hr className="w-100 mt-3"></hr>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Описание работы</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray">Описание должно описывать одну должность</p>
                                    {!desc && <span className="text-danger">{error}</span>}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100">
                                        <div className="row d-flex">
                                            <textarea 
                                                className="form-control bg-white" 
                                                id="desc"
                                                value={desc} 
                                                maxLength={1000}
                                                onChange={(e)=> setDesc(e.target.value)}
                                                rows={4}
                                                placeholder="Введите описание должности" 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-4 d-flex align-items-start">
                                <div>
                                    <div className="fw-bold">Обязанности</div>
                                    <p style={{fontSize:"14px"}} className="text-color-gray">Опишите основные обязанности на этой должности</p>
                                    {!requirements && <span className="text-danger">{error}</span>}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="w-100">
                                        <div className="row d-flex">
                                            <textarea 
                                                className="form-control bg-white" 
                                                id="requirements"
                                                value={requirements} 
                                                maxLength={1000}
                                                onChange={(e)=> setRequirements(e.target.value)}
                                                rows={4}
                                                placeholder="Введите должностные обязанности" 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="w-100 mt-3"></hr>
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <button className="btn-violet large" style={{borderRadius:"0.375rem"}} onClick={(()=> PostCreateVacancy())}>Опубликовать</button>
                        </div>
                    </>)
                }
            </Box>
        </>    
    )
}