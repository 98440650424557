import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"


export function CompanyRegistration(){
    useEffect(() => {
        document.title = "Регистрация компании";
    }, []);
    const navigate = useNavigate(); 
    const handleEditClick = (id: number | null) => {
        if (id !== null) { 
            navigate(`/companyVerification/${id}`);
        }
    };

    return(
        <>
            <div className="container mt-5">
                <div className="text-left mb-4">
                    <h3 className="fw-bold">Шаг 1 из 3</h3>
                    <p>Вы регистрируетесь как:</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 mb-3">
                        <button className="card text-center h-100 btn-bright large" onClick={()=>handleEditClick(1)} style={{borderRadius:"20px"}}>
                            <img src="/images/icons/legal-entity.png" className="card-img-top mb-3 w-100" alt="Юридическое лицо" />
                            <h4 className="card-title fw-bold">Юридическое лицо</h4>
                            <p style={{fontSize:"15px"}} className="card-text">Оформленное юр. лицо или ИП</p>
                        </button>
                    </div>
                    <div className="col-md-4 mb-3">
                        <button className="card text-center h-100 btn-bright large" onClick={()=>handleEditClick(2)} style={{borderRadius:"20px"}}>
                            <img src="/images/icons/individual-entrepreneur.png" className="card-img-top mb-3 w-100" alt="Физическое лицо" />
                            <h4 className="card-title fw-bold">Физическое лицо</h4>
                            <p style={{fontSize:"15px"}} className="card-text">Или оформили самозанятость</p>
                        </button>
                    </div>
                    {/* <div className="col-md-4 mb-3">
                        <button className="card text-center h-100 btn-bright large" onClick={()=>handleEditClick(3)} style={{borderRadius:"20px"}}>
                            <img src="/images/icons/university-representative.png" className="card-img-top mb-3 w-100" alt="Университет" />
                            <h4 className="card-title fw-bold">Университет</h4>
                            <p style={{fontSize:"15px"}} className="card-text">Вы являетесь представителем ВУЗа</p>
                        </button>
                    </div> */}
                </div>
                </div>
        </>
    )
}