import axios, { AxiosRequestConfig } from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { Context } from "../../App";
import { useContext } from "react";
export const API_URL=process.env.REACT_APP_HOST_API

export const $anon_api = axios.create({
    baseURL: API_URL
})

export const $api = axios.create({
    withCredentials: false,
    baseURL: API_URL
})

$api.interceptors.request.use((config) => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  }
  else{
    config.headers.Authorization = null;
  }
  return config;
})

$api.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
        originalRequest._isRetry = true;
        try {
          const refreshToken = localStorage.getItem('token2');
          if (refreshToken) {
            // Отправляем запрос на обновление токена с использованием refresh_token
            const response = await axios.post<AuthResponse>(
              `${API_URL}auth/refresh_token`,
              { refresh_token: refreshToken },
              { withCredentials: false }
            );
            // Обновляем токен в локальном хранилище
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('token2', response.data.refresh_token);
  
            // Повторяем оригинальный запрос с обновленным токеном
            return $api.request(originalRequest);
          } else {
            // Если нет refresh_token, вы можете выполнить необходимые действия
          }
        } catch (e) {
          const store = useContext(Context);
          store?.logout();
          localStorage.removeItem('token');
          localStorage.removeItem('token2');
        }
      }
      throw error;
    }
  );
  

