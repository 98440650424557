import React, { useEffect, useState } from 'react';
import GetGeneralInformationService, { City } from '../api/services/GetGeneralInformationService';
import Select from 'react-select';


interface CitySelectProps {
    onCitySelect: (cityId: number | null) => void;
    initialCity?: City | null;
    itemsPerPage?: number;
    styleClass?: string;
    initialCityId?: number | null;
}

const CitySelector: React.FC<CitySelectProps> = ({ onCitySelect, initialCity: initialCity, itemsPerPage = 10, styleClass, initialCityId}) => {
    const [city_id, setCityId] = useState<number | null>(null);
    const [citys, setCitys] = useState<City[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [endOfScroll, setEndOfScroll] = useState<boolean>(false); 
    const [lastExistedPage, setLastExistedPage] = useState<number>(1);


    useEffect(() => {
        if (initialCity) {
            const fetchInitialCity = async () => {
                setLoading(true);
                try {
                    const response = await GetGeneralInformationService.GetCitys(initialCity.name ?? '');
                    setCitys(prevCitys => {
                        const newCitys = response.data;
                        const uniqueCitys = [...prevCitys, ...newCitys].filter((city, index, self) =>
                            index === self.findIndex((c) => c.id === city.id)
                        );
                        return uniqueCitys;
                    });
                    setCityId(initialCity?.id ?? null);
                } catch (error) {
                    // console.error("Ошибка при загрузке городов:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchInitialCity();
        }
        const fetchCitys = async () => {
            setLoading(true);
            try {
                const response = await GetGeneralInformationService.GetCitys('', 1, 10);
                setCitys(prevCitys => {
                        const newCitys = response.data;
                        const uniqueCitys = [...prevCitys, ...newCitys].filter((city, index, self) =>
                            index === self.findIndex((c) => c.id === city.id)
                    );
                    return uniqueCitys;
                });
            } catch (error) {
                // console.error("Ошибка при загрузке городов:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCitys();
    }, [initialCity]);

    useEffect(() => {
        const fetchCitys = async () => {
            if (loading) return;
            if (endOfScroll === true) return;
            
            setLoading(true);
            try {
                const response = await GetGeneralInformationService.GetCitys(searchTerm, currentPage, itemsPerPage);
                setCitys(prevCitys => {
                    const newCitys = response.data;
                    const uniqueCitys = [...prevCitys, ...newCitys].filter((city, index, self) =>
                        index === self.findIndex((c) => c.id === city.id)
                    );
                    if(newCitys.length === 0 && currentPage > lastExistedPage) {
                        setLastExistedPage(currentPage);
                        setEndOfScroll(true);
                        // console.log(endOfScroll);
                    }
                    return uniqueCitys;
                });
            } catch (error) {
                // console.error("Ошибка при загрузке городов:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCitys();
    }, [currentPage, searchTerm]);


    useEffect(() => {
        // console.log('initialCityId:', initialCityId);
        setCityId(initialCityId ?? null);
    }, [initialCityId]);

    
    const handleCityChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedCityId = parseInt(selectedOption.value);
            // console.log('Выбранный город ID:', selectedCityId); // Выводим выбранный ID в консоль
            setCityId(selectedCityId);
            onCitySelect(selectedCityId); // Вызываем функцию для обработки выбора
        } else {
            setCityId(null);
            onCitySelect(null);
            // console.log('Город не выбран'); // Выводим сообщение, если город не выбран
        }
    };


    const handleInputChange = (inputValue: string) => {
        setSearchTerm(inputValue);
        setCurrentPage(1); // Сбросить страницу при изменении поиска
    };

    const handleScroll = (event: WheelEvent | TouchEvent) => {
        const target = event.target as HTMLDivElement;
        console.log(target.scrollHeight, target.scrollTop, target.clientHeight);
        if (target.scrollHeight - target.scrollTop <= target.clientHeight + 20 && !loading) {
            setCurrentPage(prevPage => prevPage + 1); // Загружаем следующую страницу
        }
    };

    const cityOptions = citys.map(city => ({
        value: city.id?.toString() ?? '',
        label: city.name ?? '',
    }));


    return (
        <>
        {styleClass === "bg-gray" ?
            <Select
            isClearable
            options={cityOptions}
            className={`basic-multi-select me-0 ${styleClass}`}
            classNamePrefix="select"
            value={cityOptions.find(option => option.value === city_id?.toString()) ?? null}
            onChange={handleCityChange}
            onInputChange={handleInputChange}
            captureMenuScroll={true}
            placeholder="Город"
            onMenuScrollToBottom={handleScroll}
            noOptionsMessage={() => 'Ничего не найдено'}
            styles={{
                control: (base) => ({
                    ...base,
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#f0f0f0',
                    position: 'relative',
                    // zIndex: 2,
                    pointerEvents: 'auto',
                    marginTop: '1px',
                    marginBottom: '1px',    
                    ":active": {
                        borderColor: "black !important",
                        backgroundColor: '#f0f0f0 !important',
                        position: 'relative',
                        // zIndex: 2,
                        pointerEvents: 'auto',
                        marginTop: '1px',
                        marginBottom: '1px',
                    }
                    })
                }}
            />

            :
            <Select
                isClearable
                options={cityOptions}
                className={`basic-multi-select me-0 ${styleClass} `}
                classNamePrefix="select"
                value={cityOptions.find(option => option.value === city_id?.toString())}
                onChange={handleCityChange}
                onInputChange={handleInputChange}
                captureMenuScroll={true}
                placeholder="Город"
                onMenuScrollToBottom={handleScroll}
                noOptionsMessage={() => 'Ничего не найдено'}
            /> 

        }
        </>
    );
};

export default CitySelector;
