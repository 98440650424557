interface BoxProps {
    title?: string;
    colorClass?: string;
    children: React.ReactNode;
    styleClass?: string;
}

export function Box(props: BoxProps) {
    return (
        <div className={`bs py-3 py-md-4 px-3 px-md-5 mb-2 ${props.styleClass}`}>
            {props.title && <h3 className="fw-bold">{props.title}</h3>}
            {props.children}
        </div>
    );
}

export function BoxMini(props: BoxProps) {
    return (
        <div className={`content-box-mini mb-4 bs py-2 px-3 py-md-4 pe-md-4 ps-md-4 ${props.styleClass}`}>
            {props.title && <h5 className="block-title-mini">{props.title}</h5>}
            {props.children}
        </div>
    );
}

export function BoxMap(props: BoxProps) {
    return (
        <div className={`content-box-maps bs ${props.styleClass}`}>
            {props.title && <h5 className="block-title-mini">{props.title}</h5>}
            {props.children}
        </div>
    );
}
