import { useEffect, useState } from "react";
import GetGeneralInformationService, { Direction } from "../api/services/GetGeneralInformationService";
import Select from 'react-select';


export interface Props{
    onDirectionSelect: (directionId: number | null) => void
    initialDirection?: Direction | null;
    styleClass?: string;
    initialDirectionId?: number | null
}

const DirectionSelector: React.FC<Props> = function({ onDirectionSelect,  initialDirection, styleClass, initialDirectionId}) {
    const [directions, setDirections] = useState<Direction[]>([]);
    const [directionId, setDirectionId] = useState<number | null>(initialDirection?.id ?? null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await GetGeneralInformationService.GetDirections();
                setDirections(response.data);
            }
            catch(error){
                // console.log("Ошибка при загрузке данных:", error);
            };
        };
        fetchData();
    }, [])

    useEffect(() => {
        setDirectionId(initialDirectionId ?? null);
    }, [initialDirectionId]);

    const handleDirectionChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedDirectionId = parseInt(selectedOption.value);
            // console.log('Выбранное направление ID:', selectedDirectionId); // Выводим выбранный ID в консоль
            setDirectionId(selectedDirectionId);
            onDirectionSelect(selectedDirectionId); // Вызываем функцию для обработки выбора
        } else {
            setDirectionId(null);
            onDirectionSelect(null);
            // console.log('Направление  не выбран'); // Выводим сообщение, если город не выбран
        }
    };

    const handleInputChange = (inputValue: string) => {
        setSearchTerm(inputValue);
    };

    const directionOptions = directions.map(direction => ({
        value: direction.id?.toString() ?? '',
        label: direction.name ?? '',
    }));

    return (
        <>
        {styleClass === "bg-gray" ?
            <Select
            isClearable
            options={directionOptions}
            className={`basic-multi-select me-0 ${styleClass}`}
            classNamePrefix="select"
            value={directionOptions.find(option => option.value === directionId?.toString()) ?? null}
            onChange={handleDirectionChange}
            onInputChange={handleInputChange}
            placeholder="Направление"
            noOptionsMessage={() => 'Ничего не найдено'}
            styles={{
                control: (base) => ({
                    ...base,
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#f0f0f0',
                    position: 'relative',
                    // zIndex: 1,
                    pointerEvents: 'auto',
                    marginTop: '1px',
                    marginBottom: '1px',    
                })
            }}
        />
            :
            <Select
            isClearable
            options={directionOptions}
            className={`basic-multi-select me-0 ${styleClass} `}
            classNamePrefix="select"
            value={directionOptions.find(option => option.value === directionId?.toString())}
            onChange={handleDirectionChange}
            onInputChange={handleInputChange}
            placeholder="Направление"
            noOptionsMessage={() => 'Ничего не найдено'}
            /> 

        }
        </>
    );
}

export default DirectionSelector;