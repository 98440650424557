import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <div className="loading-screen">
      <div className="loading-animation">
        <span className="letter">C</span>
        <span className="letter">a</span>
        <span className="letter">m</span>
        <span className="letter">p</span>
        <span className="letter">u</span>
        <span className="letter">s</span>
      </div>
    </div>
  );
};

export default LoadingScreen;
