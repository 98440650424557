import React, { useState, useEffect, useRef } from 'react';
import Creatable from 'react-select/creatable';
import { MultiValue, ActionMeta, SingleValue, GroupBase } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

// Определите интерфейс для опции
interface Option {
  value: string;
  label: string;
}

// Определите интерфейс для пропсов
interface CustomSelectProps {
  initialOptions?: Option[];
  onChange: (selectedValues: string | string[]) => void; // Изменяем тип на массив строк или строку
  initialSelectedValues?: string[]; // Добавляем пропс для начальных выбранных значений
  isMulti?: boolean; // Пропс для выбора одного или нескольких значений
  styleClass?: string;
  createOption?: boolean;
  placeholder?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  initialOptions,
  onChange,
  initialSelectedValues,
  styleClass,
  isMulti = true,
  createOption,
  placeholder
}) => {
  const [options, setOptions] = useState<Option[]>(initialOptions ?? []);
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<Option> | SingleValue<Option>>(isMulti ? [] : null);
  const inputRef = useRef<HTMLInputElement>(null); // Ссылка на input

  // Эффект для инициализации выбранных значений
  useEffect(() => {
    if (initialSelectedValues) {
      const formattedOptions = initialSelectedValues.map(value => ({ value, label: value }));
      setSelectedOptions(formattedOptions);
    }
  }, [initialSelectedValues]);

  // Обработчик изменения
  const handleChange = (selected: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    let values: string | string[] | undefined;

    if (isMulti) {
      const selectedArray = selected as MultiValue<Option>;
      if (selectedArray.length > 5) {
        // Ограничиваем количество выбранных элементов до 5
        return;
      }
      values = selectedArray.map((option: Option) => option.value); // Возвращаем value
      setSelectedOptions(selectedArray);
    } else {
      values = (selected as SingleValue<Option>)?.value; // Возвращаем value
      setSelectedOptions(selected);
    }
    // Проверка на undefined перед вызовом onChange
    if (values !== undefined && values !== null) {
      onChange(values); // Вызываем функцию для обновления состояния
    }
  };

  // Обработчик создания новой опции
  const handleCreate = (inputValue: string) => {
    const newOption: Option = { value: inputValue, label: inputValue };

    setOptions(prevOptions => [...prevOptions, newOption]);

    // Добавляем новую опцию в выбранные значения
    setSelectedOptions(prevSelected => {
      const updatedSelected = isMulti
        ? [...(prevSelected as MultiValue<Option>), newOption]
        : newOption;

        if (isMulti && Array.isArray(updatedSelected) && updatedSelected.length > 5) {
          // Ограничиваем количество выбранных элементов до 5
          return prevSelected as MultiValue<Option>; // Возвращаем предыдущее значение
        }

      let values: string | string[] | undefined;

      if (isMulti) {
        values = (updatedSelected as MultiValue<Option>).map((option: Option) => option.value); // Возвращаем value
      } else {
        values = (updatedSelected as SingleValue<Option>)?.value; // Возвращаем value
      }

      // Проверка на undefined перед вызовом onChange
      if (values !== undefined) {
        onChange(values); // Вызываем функцию для обновления состояния
      }

      return updatedSelected;
    });
  };

  // Обработчик клика вне компонента
  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      const inputValue = inputRef.current.value;
      if (inputValue) {
        handleCreate(inputValue);
        inputRef.current.value = ''; // Очищаем поле ввода
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const creatableRef = useRef<Select<Option, boolean, GroupBase<Option>>>(null);
  return (
    <Creatable
      ref={creatableRef}
      isMulti={isMulti}
      name="colors"
      options={options ?? []}
      className={`basic-multi-select w-100 me-0 ${styleClass}`}
      classNamePrefix="select"
      value={selectedOptions}
      defaultValue={(initialSelectedValues?.length !== 0 && initialSelectedValues) ? initialSelectedValues.map(value => ({ value, label: value })) : null}
      onChange={handleChange}
      isSearchable={createOption}
      onCreateOption={createOption ? handleCreate : undefined}
      placeholder={placeholder ?? "Выберите или впишите..."}
      noOptionsMessage={() => ""}
      formatCreateLabel={inputValue => `Добавить "${inputValue}"...`}
    />
  );
};

export default CustomSelect;
