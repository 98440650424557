import { $anon_api, $api } from "./../http/index"
import {AxiosResponse} from 'axios';
import {AuthResponse} from "../models/response/AuthResponse";
import { json } from "stream/consumers";
import { User, UserBriefInformation, UserInformation } from "../models/User";
import { Experience, ExperienceRequest, ProfileResumeInformation, ResumeInformation } from "../models/Resume";
import { EducationPutRequest, EducationRequest, Education } from "../models/Education";
import { Company } from "../models/Company";

export interface RegistrationResponse{
    email:string;
}

export default class AuthService {

    static async GetUserInfo(): Promise<AxiosResponse<UserBriefInformation>> {
        return await $api.get('user/info/params')
    }

    static async registration(email: string): Promise<RegistrationResponse> {
        return await $api.post('auth/identification', {email})
    }

    static async login(email:string, code_auth: number): Promise<AxiosResponse<AuthResponse>>{
        return await $api.post('auth/auth_email', {email, code_auth})
    }
    
    static async refreshToken(refresh_token: string): Promise<AxiosResponse<AuthResponse>>{
        return await $api.post('auth/refresh_token', {refresh_token})
    }

    static async logout(): Promise<void>{
        return await $api.post('/logout')
    }

    static async getUserInformation(): Promise<AxiosResponse<UserInformation>>{
        return await $api.get('profile/basic_information')
    }

    static async postUserInformation(
        first_name: string | undefined,
        second_name: string | undefined,
        date_of_birth: Date | undefined,
        city_id: number | null,
        about_me: string | undefined,
        about_me_tags: string[], // Изменен тип на объект
    ): Promise<AxiosResponse<User>> {
        // Формируем объект для отправки
        const payload = {
            first_name: first_name || null,
            second_name: second_name || null,
            date_of_birth: date_of_birth ? date_of_birth.toISOString().split('T')[0] : null, // Преобразуем дату в строку формата YYYY-MM-DD
            city_id: city_id || null,
            about_me: about_me || null,
            about_me_tags: about_me_tags || [] // Передаем объект тегов
        };

        return await $api.post('profile/basic_information', payload);
    }

    static async PostUpdateAvatar(picture: FormData): Promise<AxiosResponse<string>>{
        return await $api.post('profile/update_avatar', picture, 
            {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
    }

    static async DeleteAvatar(): Promise<AxiosResponse<string>>{
        return await $api.delete('profile/update_avatar')
    }

    static async postResumeInformation(name_post: string | undefined, skills: string[] | undefined, work_format: number | undefined, languages: number[] | undefined): Promise<AxiosResponse<string>>{
        return await $api.post('/resume/basic_data', {name_post, skills, work_format, languages})
    }
    
    static async getResumeInformation(): Promise<AxiosResponse<ResumeInformation>>{
        return await $api.get('/resume/all_info/')
    }

    static async postAddWorkExperience(experience: ExperienceRequest[]): Promise<AxiosResponse<string>> {
        return await $api.post('/resume/work_experience', experience);
    }
    
    static async putEditWorkExperience(experience: Experience[]):Promise<AxiosResponse<string>>{
        return await $api.put('/resume/work_experience', experience)
    }
    static async deleteWorkExperience(ids: number[]): Promise<AxiosResponse<string>> {
        const response = await $api.delete('/resume/work_experience', {
            data: { ids },
        });
        return response;
    }

    static async getEducation(): Promise<AxiosResponse<Education[]>> {
        return await $api.get('/resume/education');
    }
    static async postAddEducation(education: EducationRequest[]): Promise<AxiosResponse<string>> {
        return await $api.post('/resume/education', education);
    }

    static async putEditEducation(education: EducationPutRequest): Promise<AxiosResponse<string>> {
        return await $api.put('/resume/education', education);
    }

    static async deleteEducation(id: number): Promise<AxiosResponse<string>> {
        const response = await $api.delete('/resume/education', {
            data:  {id},
        });
        return response;
    }

    static async getCompanyInformation(): Promise<AxiosResponse<Company>> {
        return await $api.get('/company/info');
    }

    static async postCreateCompany(inn: string, name_company: string, phone: string, email: string, type_company: number): Promise<AxiosResponse<Company>> {
        return await $api.post('/company/create_company', {inn, name_company, phone, email, type_company});
    }

    static async PostUpdateCompanyAvatar(picture: FormData): Promise<AxiosResponse<string>>{
        return await $api.post('/company/update_avatar', picture, 
            {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
    }

    static async DeleteCompanyAvatar(): Promise<AxiosResponse<string>>{
        return await $api.delete('/company/update_avatar')
    }

    static async putCompanyInformation(city_id: number | null, name_company: string, about: string, social_network_url: string): Promise<AxiosResponse<string>> {
        return await $api.put('/company/info', {city_id, name_company, about, social_network_url});
    }

    static async getCompanyInfo(company_id: number): Promise<AxiosResponse<Company>> {
        return await $anon_api.get(`/company/info/${company_id}`);
    }

    static async getProfileBasicInfoWithId(user_id: number): Promise<AxiosResponse<UserInformation>> {
        return await $api.get(`/profile/basic_information/${user_id}`);
    }

    static async getResumeWithId(user_id: number): Promise<AxiosResponse<ProfileResumeInformation>> {
        return await $api.get(`/resume/all_info/${user_id}`);
    }    

    static async CanDownloadResume(): Promise<AxiosResponse<boolean>> {
        return await $api.get(`/profile/can_downdload_resume`);
    }
}