import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import VacancyService from "../api/services/VacancyService";
import { VacancyFullInformation } from "../api/models/response/VacancyResponse";
import { Box, BoxMini } from "../components/Box";
import { Tag } from "../components/Tag";
import { Context } from "../App";

export function Vacancy() {
    const { id } = useParams<{ id: string }>();
    const vacancyId = id ?? "0";
    const [vacancy, setVacancy] = useState<VacancyFullInformation>();
    const [responded, setResponded] = useState(false);
    const store = useContext(Context);

    useEffect(() => {
        document.title = vacancy?.name ?? "Вакансия";
    }, [vacancy]);

    const postRespondVacancy = async () => {
        try {
            const response = await VacancyService.PostRespondVacancy(Number(vacancyId));
            // console.log(response.data);
            setResponded(true);
        } catch (error) {
            // console.error("Error responding to vacancy:", error);
        }
    };

    useEffect(() => {
        const fetchVacancy = async () => {
            try {
                const response = await VacancyService.GetFullInformation(Number(vacancyId));
                setVacancy(response.data);
            } catch (error) {
                // console.error("Ошибка загрузки вакансии:", error);
            }
        };
        fetchVacancy();
    }, [vacancyId]);

    const navigate = useNavigate();
    const handleEditVacancy = (id: number | null) => {
        if (id !== null) {
            navigate(`/editVacancy/${id}`);
        }
    };

    return (
        <>  
            { vacancy ? (
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-8 d-flex">
                            <BoxMini styleClass="bg-white flex-fill">
                                <h2 className="fw-bold mb-3">{vacancy?.name}</h2>
                                <div className="d-flex mb-3 flex-wrap"> 
                                    {vacancy?.salary_from !== 0 &&<div>
                                        от
                                         <span className="fw-bold ms-1 me-1">{vacancy?.salary_from}₽</span> 
                                    </div>}
                                    { vacancy?.salary_to !== 0 &&<div>
                                         до
                                         <span className="fw-bold me-1"> {vacancy?.salary_to}₽ </span>
                                    </div>}
                                    до вычета налогов
                                </div>
                                <div className="mb-3">{vacancy?.city?.name}, {vacancy?.city?.country}</div>
                                <div>{vacancy?.work_format}</div>
                            </BoxMini>
                        </div>
                        <div className="col-md-4 d-flex">
                            <BoxMini styleClass="bg-white p-3 flex-fill">
                                <div className="row align-items-center"> 
                                    <div className="col-4">
                                        {
                                            vacancy?.company_info?.photo_url ? (
                                                <img src={vacancy?.company_info?.photo_url} alt="Company Logo" className="img-fluid rounded" />
                                            ) : (
                                                <img src="/images/icons/company/company-default.png" alt="Company Logo" className="img-fluid rounded" />
                                            )
                                        }
                                    </div>
                                    <div className="col-8">
                                        <div className="mb-2 fw-bold">{vacancy?.company_info?.name}</div>
                                        <div className="d-flex align-items-center">
                                            <img src="/images/icons/location-dark.png" alt="Location Icon" style={{ width: "20px", height: "20px", marginRight: "8px" }} />
                                            <div>{vacancy?.city?.name}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="mb-2">{vacancy.company_info?.about}</div>
                                    {vacancy?.company_info?.social_network_url && (
                                        <a className="underline" target="_blank" rel="noopener noreferrer">
                                            {vacancy?.company_info?.social_network_url} 
                                        </a>
                                    )}
                                </div>
                            </BoxMini>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 ">
                            <BoxMini styleClass="bg-white">
                                <div className="mb-3">
                                    <h4 className="mb-1 fw-bold">О компании</h4>
                                    <span>{vacancy?.description}</span>
                                </div>
                                <div className="mb-3">
                                    <h4 className="mb-1 fw-bold">Обязанности</h4>
                                    <span>{vacancy?.requirements}</span>
                                </div>
                                <div>
                                    <h4 className="mb-1 fw-bold">Навыки</h4>
                                    <div className="row row-cols-auto gap-3 ms-0">
                                        {vacancy?.skills?.map((skill, index) => (
                                            <Tag tag={skill} styleClass={"bg-gray"} key={index} />
                                        ))}
                                    </div>
                                </div>
                            </BoxMini>
                        </div>
                        <div className="col-md-4 mb-4">
                            { store?.isAuth &&
                                (vacancy?.company_info?.id !== store?.userBriefInformation?.company_id) ?
                                    (vacancy?.is_responded || responded) ? 
                                        (
                                            <button className="btn-violet large opacity-50 w-100 bs" disabled style={{borderRadius:"0.375rem"}}>
                                                Вы уже откликнулись
                                            </button>
                                        )
                                        :
                                        (
                                            <button className="btn-violet large w-100 bs" onClick={postRespondVacancy} style={{borderRadius:"0.375rem"}}>
                                                Откликнуться
                                            </button>
                                        )
                                :
                                vacancy?.company_info?.id === store?.userBriefInformation?.company_id &&
                                    <button className="btn-violet large w-100 bs" style={{borderRadius:"0.375rem"}} onClick={() => handleEditVacancy(vacancy?.id ?? null)}>
                                        Редактировать вакансию
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            ) :
                <Box styleClass="bg-white">
                    <div className="d-flex justify-content-center align-items-center"> 
                        <h3>Вакансия не найдена</h3>
                    </div>
                </Box>

            }
        </>
    );
}
