import { useContext, useEffect} from "react";
import { Box, BoxMini } from "../../../components/Box";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../App";

export function EditWorkExperiences(){
    useEffect(() => {
        document.title = "Редактирование мест работы";
    }, []);

    const store = useContext(Context);

    const navigate = useNavigate(); // Получите доступ к navigate
    const handleEditClick = (id: number | null) => {
        if (id !== null) { // Проверка на null
            navigate(`/editExperience/${id}`); // Перенаправление на страницу редактирования с id
        }
    };

    
    return(
        <>
            <Box styleClass="bg-white">
                <div className="">
                    <div className="row">
                        <div>
                            <Link to={`/profile/${store?.userBriefInformation?.user_id}`} className=" text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться к профилю без сохранения</Link>
                        </div>
                        <div className="col d-flex justify-content-start align-items-center">
                            <span className="text-color-gray fw-bold me-3" style={{fontSize:"16px"}}>Опыт работы</span>
                        </div>
                        <div className="col d-flex justify-content-end align-items-center">
                            <Link to="/addWorkExperience" className="me-3 text-decoration-none">
                                <img className="" src="/images/icons/add.png" style={{width:"17px", height:"17px"}}></img>
                                <a className=" text-decoration-none" href="/addWorkExperience" style={{color:"blue"}}>Добавить</a>
                            </Link>
                        </div>
                        <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                            <hr className="m-0"></hr>
                        </p>
                    </div>
                    {store?.resume?.experiences && store.resume.experiences.map((experience) => (
                    <BoxMini styleClass="bg-gray position-relative" key={experience.id}>
                        <div className="d-flex justify-content-end d-md-none">
                            <button className="btn bg-white small me-2 p-1" onClick={() => handleEditClick(experience.id)}>
                                <div className="d-flex align-items-center">
                                    <img src="/images/icons/editing-inverse.png" style={{ width: "17px", height: "17px" }} alt="Редактировать" />
                                    <span className="fs-7" style={{ color: "blue" }}>Редактировать</span>
                                </div>
                            </button>
                            <button className="btn bg-white small p-1" onClick={() => store.deleteWorkExperience([experience.id ?? 1])}>
                                <div className="d-flex align-items-center">
                                    <img src="/images/icons/close.png" style={{ width: "17px", height: "17px" }} alt="Удалить" />
                                    <span className="fs-7" style={{ color: "blue" }}>Удалить</span>
                                </div>
                            </button>
                        </div>
                        <div className="d-none d-md-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                            <button className="btn bg-white small me-2" onClick={() => handleEditClick(experience.id)}>
                                <div className="d-flex align-items-center">
                                    <img src="/images/icons/editing-inverse.png" style={{ width: "17px", height: "17px" }} alt="Редактировать" />
                                    <span className="fs-7" style={{ color: "blue" }}>Редактировать</span>
                                </div>
                            </button>   
                            <button className="btn bg-white small" onClick={() => store.deleteWorkExperience([experience.id ?? 1])}>
                                <div className="d-flex align-items-center">
                                    <img src="/images/icons/close.png" style={{ width: "17px", height: "17px" }} alt="Удалить" />
                                    <span className="fs-7" style={{ color: "blue" }}>Удалить</span>
                                </div>
                            </button>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="fw-bold">{experience.title_position}</h5>
                            {/* <div>
                                <button className="btn bg-white small me-2" onClick={() => handleEditClick(experience.id)}>
                                    <div className="d-flex align-items-center">
                                        <img src="/images/icons/editing-inverse.png" style={{ width: "17px", height: "17px" }} alt="Редактировать" />
                                        <span className="fs-7" style={{ color: "blue" }}>Редактировать</span>
                                    </div>
                                </button>
                                <button className="btn bg-white small" onClick={() => store.deleteWorkExperience([experience.id ?? 1])}>
                                    <div className="d-flex align-items-center">
                                        <img src="/images/icons/close.png" style={{ width: "17px", height: "17px" }} alt="Удалить" />
                                        <span className="fs-7" style={{ color: "blue" }}>Удалить</span>
                                    </div>
                                </button>
                            </div> */}
                        </div>
                        <h5 className="fw-bold mb-2" style={{ color: "blue" }}>{experience.company_name}</h5>
                        <span className="mb-2 d-block" style={{ color: "gray" }}>{experience.getting_started} - {experience.end_work}</span>
                        <span>{experience.description}</span>
                    </BoxMini>
                ))}
            </div>
        </Box>
        </>
    );
}