import { useEffect, useState } from "react";
import { Language } from "../api/models/Resume";
import GetGeneralInformationService from "../api/services/GetGeneralInformationService";

interface CitySelectProps {
    onCitySelect: (resumeLanguages: number[]) => void;
    initialLanguages?: Language[]; // Начальные значения
}

export function LanguageSelector({ onCitySelect, initialLanguages }: CitySelectProps) {
    const [languages, setLanguages] = useState<Language[]>([]);
    const [resumeLanguages, setResumeLanguages] = useState<number[]>([]);

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await GetGeneralInformationService.GetLanguages();
                setLanguages(response.data);
            } catch (error) {
                // console.error("Error fetching languages:", error);
            }
        };
        fetchLanguages();
    }, []);

    useEffect(() => {
        // Инициализируем состояние с начальными языками
        if (initialLanguages) {
            setResumeLanguages(initialLanguages ? initialLanguages.map(language => language.id).filter((id): id is number => id !== null) ?? [] : []);
        }
    }, [initialLanguages]);
    useEffect(() => {
        // Вызываем функцию обратного вызова при изменении resumeLanguages

        onCitySelect(resumeLanguages);
    }, [resumeLanguages, onCitySelect]); // Зависимость от resumeLanguages и onCitySelect

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);

        if (event.target.checked) {
            // Добавляем значение в массив, если checkbox выбран
            setResumeLanguages(prev => [...prev, value]);
        } else {
            // Удаляем значение из массива, если checkbox снят
            setResumeLanguages(prev => prev.filter(id => id !== value));
        }
    };

    const groupedLanguages = languages.reduce((acc, language) => {
        const languageName = language.name as string;
        if (!acc[languageName]) {
            acc[languageName] = [];
        }
        acc[languageName].push(language);
        return acc;
    }, {} as Record<string, Language[]>);

    return (
        <>
            {Object.entries(groupedLanguages).map(([name, langGroup]) => (
                <div key={name}>
                    <div >{name}:</div>
                    {langGroup.map((language, index) => (
                        <div className="form-check" key={language.id} style={{ marginLeft: '20px' }}>
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                name="languages" 
                                id={`language-${language.id}`}
                                value={language.id as number}
                                checked={resumeLanguages.includes(language.id as number)}
                                onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label" htmlFor={`language-${language.id}`}>
                                {language.level}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
}