import { Route, Routes } from "react-router-dom";
import { Page } from "./components/Page";
import { Vacancys } from "./pages/Vacancys";
import "./css/App.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import { Authorization } from "./pages/Authorization";
import { createContext, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { CompanyVacancys } from "./pages/Company/CompanyVacancys";
import { MyCandidates } from "./pages/MyCandidates";
import { Collections } from "./pages/Collections";
import { CompanyRegistration } from "./pages/Company/CompanyRegistration";
import LoadingScreen from "./components/LoadingScreen";
import { EditWorkExperience } from "./pages/Forms/EditFroms/EditWorkExperience";
import { EditWorkExperiences } from "./pages/Forms/EditFroms/EditWorkExperiences";
import { State, useStore } from "./api/store/store";
import React from "react";
import { Profile } from "./pages/Profile";
import { EditProfessionalProfile } from "./pages/Forms/EditFroms/EditProfessionalProfile";
import { EditSelfInformation } from "./pages/Forms/EditFroms/EditSelfInformation";
import { EditEducations } from "./pages/Forms/EditFroms/EditEducations";
import { EditEducation } from "./pages/Forms/EditFroms/EditEducation";
import { CompanyPage } from "./pages/Company/Company";
import { EditCompanyInformation } from "./pages/Forms/EditFroms/EditCompanyInformation";
import { CompanyVerification } from "./pages/Company/CompanyVerification";
import { Vacancy } from "./pages/Vacancy";
import { EditVacancy } from "./pages/Forms/EditFroms/EditVacancy";
import { Navigate } from "react-router-dom";
import { AddVacancy } from "./pages/Forms/AddForms/AddVacancy";
import { AddWorkExperience } from "./pages/Forms/AddForms/AddWorkExperience";
import { AddEducation } from "./pages/Forms/AddForms/AddEducation";
import { VacancyResponded } from "./pages/VacancyResponded";
import UserAgreement from "./pages/Documents/UserAgreement";
import PrivacyPolicy from "./pages/Documents/PrivacyPolicy";
import Consent from "./pages/Documents/Consent";
import AboutUs from "./pages/AboutUs";
import NotFoundedPage from "./pages/NotFoundedPage";


export const Context = React.createContext<State | null>(null);


export function App(){
   const store = useStore();
  // useEffect(() => {
  //   if (localStorage.getItem('token') && store?.isAuth) {
  //     const initStore = async () => {
  //       await store.checkAuth();
  //     };
  //     initStore();
  //   }
  // }, [store]);

  if (store?.isLoading) {
    return <LoadingScreen/>;
    // <LoadingComponent />; 
    // <LoadingSpinner />;
  }

  return (
    <>
      <Context.Provider value={store}>
        <Routes>
          <Route path="/authorization" element={<Authorization />} />
          <Route path="/" element={<Page />}>
            <Route path="/addVacancy" element={<AddVacancy />} />
            <Route path="/vacancy" element={<Vacancys />} />
            <Route path="/vacancy/:id" element={<Vacancy/>} />
            <Route path="/editVacancy/:id" element={<EditVacancy/>} />
            <Route path="/vacancyResponded/:id" element={<VacancyResponded/>} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/editProfessionalProfile" element={<EditProfessionalProfile />} />
            <Route path="/editSelfInformation" element={<EditSelfInformation />} />
            <Route path="/companyVacancys" element={<CompanyVacancys />} />
            <Route path="/myCandidates" element={<MyCandidates />} />
            <Route path="/companyRegistration" element={<CompanyRegistration />} />
            <Route path="/companyVerification/:id" element={<CompanyVerification/>}/>
            <Route path="/editCompanyInformation" element={<EditCompanyInformation />} />
            <Route path="/addWorkExperience" element={<AddWorkExperience />} />
            <Route path="/editWorkExperiences" element={<EditWorkExperiences />} />
            <Route path="/editExperience/:id" element={<EditWorkExperience/>} />
            <Route path="/editEducations" element={<EditEducations/>} />
            <Route path="/editEducation/:id" element={<EditEducation/>} />
            <Route path="/addEducation" element={<AddEducation />} />
            <Route path="/company/:id" element={<CompanyPage/>}/>
            <Route path="/userAgreement" element={<UserAgreement/>}/>
            <Route path="/privacy" element={<PrivacyPolicy/>}/>
            <Route path="/consent" element={<Consent/>}/>
            <Route path="/aboutUs" element={<AboutUs/>}/>
            <Route path="/" element={<Navigate to="/vacancy" replace />} />
            <Route path="*" element={<NotFoundedPage/>}/>
          </Route>
        </Routes>
      </Context.Provider>
    </>
  );
};

