import { Link, useNavigate } from "react-router-dom";
import { Box, BoxMini } from "../../../components/Box";
import { useContext, useEffect } from "react";
import { Context } from "../../../App";

export function EditEducations() {
    useEffect(() => {
        document.title = "Редактирование образования";
    }, []);
    const  store  = useContext(Context);
    
    const navigate = useNavigate(); 
    const handleEditClick = (id: number | null) => {
        if (id !== null) {
            navigate(`/editEducation/${id}`); 
        }
    };

    return(
        <>
            <Box styleClass="bg-white">
                <div className="">
                    <div className="row">
                        <div>
                            <Link to={`/profile/${store?.userBriefInformation?.user_id}`} className=" text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться к профилю без сохранения</Link>
                        </div>
                        <div className="col d-flex justify-content-start align-items-center flex-wrap">
                            <span className="text-color-gray fw-bold me-3" style={{fontSize:"16px"}}>Образование</span>
                        </div>
                        <div className="col d-flex justify-content-end align-items-center">
                            <Link to="/addEducation" className="me-3 text-decoration-none d-flex justify-content-end align-items-center">
                                <img className="" src="/images/icons/add.png" style={{width:"17px", height:"17px"}}></img>
                                <span className="text-decoration-none d-none d-md-flex" style={{color:"blue"}}>Добавить</span>
                                <span className="text-decoration-none d-md-none" style={{color:"blue", fontSize:"14px"}}>Добавить</span>
                            </Link>
                        </div>
                        <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                            <hr className="m-0"></hr>
                        </p>
                    </div>
                    {store?.education && store?.education?.map((education) =>(
                        <BoxMini styleClass="bg-gray position-relative" key={education.id}>
                            <div className="d-flex justify-content-end d-md-none">
                                <button className="btn bg-white small me-2 p-1" onClick={() => handleEditClick(education.id)}>
                                    <div className="d-flex align-items-center">
                                        <img src="/images/icons/editing-inverse.png" style={{ width: "17px", height: "17px" }} alt="Редактировать" />
                                        <span className="fs-7" style={{ color: "blue" }}>Редактировать</span>
                                    </div>
                                </button>
                                <button className="btn bg-white small p-1" onClick={() => store.deleteEducation(education.id ?? 1 )}>
                                    <div className="d-flex align-items-center">
                                        <img src="/images/icons/close.png" style={{ width: "17px", height: "17px" }} alt="Удалить" />
                                        <span className="fs-7" style={{ color: "blue" }}>Удалить</span>
                                    </div>
                                </button>
                            </div>
                            <div className="d-none d-md-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                                <button className="btn bg-white small me-3" onClick={() => handleEditClick(education.id)}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img className="" src="/images/icons/editing-inverse.png" style={{width:"17px", height:"17px"}}></img>
                                            <span style={{color:"blue"}}>Редактровать</span>
                                        </div>
                                    </button>
                                    <button className="btn bg-white small" onClick={() => store.deleteEducation(education.id ?? 1 )}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <img className="" src="/images/icons/close.png" style={{width:"17px", height:"17px"}}></img>
                                            <span style={{color:"blue"}}>Удалить</span>
                                        </div>
                                    </button>
                            </div>
                            <div className="d-flex">
                                <div className="col d-flex justify-content-start ">
                                    <h5 className="fw-bold">{education.university?.name}</h5>
                                </div>
                            </div>
                            <h5 className="fw-bold mb-2" style={{color:"blue"}}>{education.level_of_education}</h5>
                            <span className="mb-2 d-block" style={{color:"gray"}}>{education.year_of_admission + '-' + education.year_of_graduation}</span>
                            <span>{education.specialty}</span>
                        </BoxMini>
                    ))}
                </div>
            </Box>        
        </>
    )
}