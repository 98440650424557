import { useContext, useEffect, useState } from "react";
import { Box } from "../../../components/Box";
import { Form } from "react-bootstrap";
import CustomSelect from "../../../components/CustomSelect";
import { Context } from "../../../App";
import CitySelector from "../../../components/CitySelector";
import { Link } from "react-router-dom";

export function EditSelfInformation(){
    useEffect(() => {
        document.title = "Редактирование профиля";
    }, []);

    const store = useContext(Context);

    useEffect(() => {
        setFirst_name(store?.user?.surname || "");
        setSecond_name(store?.user?.name || "");
        setDate_of_birth(store?.user?.date_of_birth ? new Date(store?.user?.date_of_birth) : undefined);
        setCity_id(store?.user?.city ? store?.user?.city.id : null);
        setAvatar_url(store?.user?.avatar_url || "");
        setAbout_me(store?.user?.about_me ?? "");
        setAboutMeTags(store?.user?.about_me_tags || [""]);
    }, [store]);

    const [first_name, setFirst_name] = useState<string | undefined>();
    const [second_name, setSecond_name] = useState<string | undefined>();
    const [date_of_birth, setDate_of_birth] = useState<Date | undefined>();
    const [city_id, setCity_id] = useState<number | null>();
    const [avatarPicture, setAvatarPicture] = useState<File | null>(null);
    const [avatar_url, setAvatar_url] = useState<string | undefined>("");
    const [about_me, setAbout_me] = useState<string | undefined>();
    const [about_me_tags, setAboutMeTags] = useState<string[]>([""]);

    const [error, setError] = useState<string | undefined>();
    const [imageError, setImageError] = useState<string | undefined>();

    const handleTagsChange = (selectedValues: string | string[]) => {
        const newTags = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
        setAboutMeTags(newTags);
    };
      
    const handleSubmit = async() => {
        setError(undefined); // Сброс ошибки
        // (!avatar_url || !avatarPicture) ||
        if (!first_name || !second_name || city_id === null ||  !about_me  || !date_of_birth) {
            setError("Пожалуйста, заполните поле.");
            return;
        }
        
        try{
            avatarPicture && await store?.postUpdateAvatar(avatarPicture);
            await store?.postUserInformation(first_name, second_name, date_of_birth, city_id ?? 0, about_me, about_me_tags);
        } catch(e){
            // console.log(e)
        }
        // try{
        // }
        // catch(e){
        //     console.log(e);
        // }
    };


    // Обработчик изменения файла
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;

        if (file) {
            const maxSizeInMB = 2; // Максимальный размер в мегабайтах
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Переводим в байты

            // Проверяем размер файла
            if (file.size > maxSizeInBytes) {
                setError('Файл должен быть меньше 2 МБ.');
                setAvatarPicture(null);
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                if (img.width > 2000 || img.height > 2000) {
                    setImageError('Изображение должно быть не больше 2000x2000 пикселей.');
                    setAvatarPicture(null);
                } else {
                    setImageError(undefined);
                    setAvatarPicture(file);
                }
            };

            img.onerror = () => {
                setImageError('Ошибка загрузки изображения.');
                setAvatarPicture(null);
            };
        }
    };

    const options = [
        { value: 'Борьба', label: 'Борьба' },
        { value: 'Греплинг', label: 'Греплинг' },
        { value: 'Фитнес', label: 'Фитнес' },
        { value: 'Чтение', label: 'Чтение' },
        { value: 'Путешествия', label: 'Путешествия' },
        { value: 'Рисование', label: 'Рисование' },
        { value: 'Музыка', label: 'Музыка' },
        { value: 'Фотография', label: 'Фотография' },
        { value: 'Кулинария', label: 'Кулинария' },
        { value: 'Велоспорт', label: 'Велоспорт' },
        { value: 'Садоводство', label: 'Садоводство' },
        { value: 'Плавание', label: 'Плавание' },
        { value: 'Игры', label: 'Игры' },
        { value: 'Настольные игры', label: 'Настольные игры' },
        { value: 'Бег', label: 'Бег' },
        { value: 'Йога', label: 'Йога' },
        { value: 'Волейбол', label: 'Волейбол' },
        { value: 'Танцы', label: 'Танцы' },
        { value: 'Кемпинг', label: 'Кемпинг' },
        { value: 'Скалолазание', label: 'Скалолазание' },
        { value: 'Рыбалка', label: 'Рыбалка' },
        { value: 'Лыжи', label: 'Лыжи' },
        { value: 'Серфинг', label: 'Серфинг' },
        { value: 'Гимнастика', label: 'Гимнастика' },
        { value: 'Медитация', label: 'Медитация' },
        { value: 'Кроссфит', label: 'Кроссфит' },
        { value: 'Пение', label: 'Пение' },
        { value: 'Шитье', label: 'Шитье' },
        { value: 'Кроссворды', label: 'Кроссворды' },
        { value: 'Вязание', label: 'Вязание' },
        { value: 'Волонтерство', label: 'Волонтерство' },
        { value: 'Изучение языков', label: 'Изучение языков' },
        { value: 'Астрономия', label: 'Астрономия' },
        { value: 'Кино', label: 'Кино' },
        { value: 'Театр', label: 'Театр' },
        { value: 'Психология', label: 'Психология' },
        { value: 'Философия', label: 'Философия' },
        { value: 'Ремесло', label: 'Ремесло' },
        { value: 'Декорирование', label: 'Декорирование' },
        { value: 'Блоггинг', label: 'Блоггинг' },
        { value: 'Подкастинг', label: 'Подкастинг' },
        { value: 'Тайцзи', label: 'Тайцзи' },
        { value: 'Картинг', label: 'Картинг' },
        { value: 'Скейтбординг', label: 'Скейтбординг' },
        { value: 'Моделирование', label: 'Моделирование' },
        { value: 'Дайвинг', label: 'Дайвинг' },
        { value: 'Сноубординг', label: 'Сноубординг' },
        { value: 'Кулинарные курсы', label: 'Кулинарные курсы' },
        { value: 'Игра на музыкальных инструментах', label: 'Игра на музыкальных инструментах' },
        { value: 'Съемка видео', label: 'Съемка видео' },
        { value: 'Картинг', label: 'Картинг' },
        { value: 'Садоводство', label: 'Садоводство' },
        { value: 'Создание сайтов', label: 'Создание сайтов' },
        { value: 'Криптография', label: 'Криптография' },
        { value: 'Флористика', label: 'Флористика' },
        { value: 'Спортивные танцы', label: 'Спортивные танцы' },
        { value: 'Кроссворды', label: 'Кроссворды' },
        { value: 'Создание комиксов', label: 'Создание комиксов' },
        { value: 'Ремонт', label: 'Ремонт' },
    ];
    
    return (
        <Box styleClass="bg-white">
            <div className="mb-4">
                <Link to={`/profile/${store?.userBriefInformation?.user_id}`} className="text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться к профилю без сохранения</Link>
                <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                    Основная информация о себе
                    <hr className="m-0"></hr>
                </p>
            </div>
            <div className="row mb-4">
                <div className="col-md-4 d-flex align-items-center">
                    <div>
                        <div className="fw-bold">Изображение профиля</div>
                        <p style={{fontSize:"12px"}} className="text-color-gray">Это изображение будет публично демонстрироваться в качестве изображения вашего профиля</p>
                        <span className="text-danger">{imageError}</span>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                    {
                        avatar_url ? 
                        <div className="me-3" style={{ position: 'relative', display: 'inline-block' }}>
                            <img 
                                src={avatar_url} 
                                className="rounded-circle" 
                                style={{ height: "186px", width: "186px", objectFit: "cover" }} 
                                alt="Profile" 
                            />
                            <button 
                                onClick={store?.deleteAvatar} 
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: '#4640DE',
                                    fontSize: '20px',
                                    padding: '0',
                                    lineHeight: '1'
                                }}
                                aria-label="Удалить изображение"
                            >
                                <span style={{
                                    display: 'inline-block',
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #4640DE',
                                    borderRadius: '50%',
                                    position: 'relative',
                                    background: 'transparent'
                                }}>
                                    <span style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '12px',
                                        height: '2px',
                                        background: '#4640DE',
                                        transform: 'translate(-50%, -50%) rotate(45deg)'
                                    }}></span>
                                    <span style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '12px',
                                        height: '2px',
                                        background: '#4640DE',
                                        transform: 'translate(-50%, -50%) rotate(-45deg)'
                                    }}></span>
                                </span>
                            </button>
                        </div>
                        :
                        <img src="/images/icons/user/user-default.jpg" className="me-3 rounded-circle" style={{height: "186px", width: "186px", objectFit: "cover"}} alt="Profile" />
                    }

                        <div className="file-upload-container flex-wrap">
                            <Form.Group controlId="formFile" className="" onChange={handleFileChange}>
                                <div className="file-upload w-100">
                                    <Form.Label htmlFor="fileInput" className="custom-file-label w-100 p-4">
                                        {avatarPicture ? (
                                            <div className="uploaded-image-container">
                                                <img
                                                    src={URL.createObjectURL(avatarPicture)}
                                                    alt="Uploaded"
                                                    className="uploaded-image"
                                                />
                                                <div className="upload-text">Изображение загружено</div>
                                            </div>
                                        ) : (
                                            <>
                                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_718_357)">
                                                        <path d="M20.5 10.6666H20.5133" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M23.1663 5.33337H9.83301C7.62387 5.33337 5.83301 7.12423 5.83301 9.33337V22.6667C5.83301 24.8758 7.62387 26.6667 9.83301 26.6667H23.1663C25.3755 26.6667 27.1663 24.8758 27.1663 22.6667V9.33337C27.1663 7.12423 25.3755 5.33337 23.1663 5.33337Z" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.83301 20.0001L11.1663 14.6667C11.7744 14.0816 12.4642 13.7736 13.1663 13.7736C13.8685 13.7736 14.5583 14.0816 15.1663 14.6667L21.833 21.3334" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M19.167 18.6667L20.5003 17.3334C21.1084 16.7482 21.7982 16.4402 22.5003 16.4402C23.2025 16.4402 23.8922 16.7482 24.5003 17.3334L27.167 20" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_718_357">
                                                            <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className="upload-text">Нажмите или перетащите</div>
                                                <div className="upload-subtext">SVG, PNG, JPG или GIF (максимум 2000 x 2000 пикселей)</div>
                                            </>
                                        )}
                                        <Form.Control 
                                            type="file" 
                                            id="fileInput" 
                                            className="custom-file-input w-100" 
                                            accept="image/*" 
                                        />
                                    </Form.Label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-4 d-flex align-items-start">
                    <div>
                        <div className="fw-bold">Информация о Вас</div>
                        <p style={{fontSize:"12px"}} className="text-color-gray">Основная информация о Вас</p>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex justify-content-start ">
                        <div className="w-100">
                            <div className="row d-flex mb-3">
                                <div className="col-6">
                                    <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>Фамилия</span>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setFirst_name(e.target.value)}
                                        placeholder="Введите фамилию"
                                        value={first_name}
                                        className="bg-white"
                                    />
                                    {!first_name && <span className="text-danger">{error}</span>}
                                </div>
                                <div className="col-6">
                                    <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>Имя</span>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => setSecond_name(e.target.value)}
                                        placeholder="Введите имя"
                                        className="bg-white"
                                        value={second_name}
                                    />
                                    {!second_name && <span className="text-danger">{error}</span>}
                                </div>
                            </div>
                            <div className="row d-flex mb-3">
                                <div className="col-12">
                                    <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>Дата рождения</span>
                                    <Form.Control 
                                        type="date" 
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setDate_of_birth(value ? new Date(value) : undefined); // Устанавливаем undefined, если значение пустое
                                        }}
                                        className="bg-white" 
                                        value={date_of_birth ? date_of_birth.toISOString().split('T')[0] : ''} // Корректно обрабатываем undefined
                                    />

                                    {!date_of_birth   && <span className="text-danger">{error}</span>}
                                </div>
                            </div>
                            <div className="row d-flex mb-3">
                                <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>Город</span>
                                <CitySelector onCitySelect={setCity_id} initialCity={store?.user?.city} />
                                {!city_id   && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="row d-flex mb-3">
                                <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>Интересы/хобби</span>
                                <CustomSelect initialOptions={options} onChange={handleTagsChange} initialSelectedValues={about_me_tags ?? []} isMulti={true} />
                                {!about_me_tags || about_me_tags.length === 0   && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="mb-3">
                                <div className="w-100">
                                    <div className="mb-3">
                                        <label htmlFor="about_me" className="form-label">Коротко о себе</label>
                                        <textarea 
                                            className="form-control bg-white" 
                                            id="about_me"
                                            value={about_me}
                                            onChange={(e) => setAbout_me(e.target.value)}
                                            rows={4}
                                            placeholder="Напишите информацию о себе" 
                                        />
                                        {!about_me && <span className="text-danger">{error}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button 
                        className="btn-violet large" 
                        onClick={() => { handleSubmit();}} > 
                        Сохранить 
                    </button>
                </div>
            </div>
        </Box>
    );
}
