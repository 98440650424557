import { Box } from "../../../components/Box";
import CustomSelect from "../../../components/CustomSelect";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../App";
import GetGeneralInformationService from "../../../api/services/GetGeneralInformationService";
import { Language, WorkFormat } from "../../../api/models/Resume";
import { LanguageSelector } from "../../../components/LanguageSelector";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

export function EditProfessionalProfile() {
    useEffect(() => {
        document.title = "Редактирование резюме";
    }, []);

    const store = useContext(Context);
    const [workFormat, setWorkFormat] = useState<WorkFormat[]>([]);

    useEffect(() => {
        fetchWorkFormats();
    }, []);

    const fetchWorkFormats = async () => {
        try {
            const response = await GetGeneralInformationService.GetWorkFormats();
            setWorkFormat(response.data);
        } catch (error) {
            // console.error("Ошибка при загрузке форматов работы:", error);
        }
    };

    useEffect(() => {
        setResumeName(store?.resume?.name_post || "");
        setResumeSkills(store?.resume?.skills || []);
        setResumeWorkFormat(store?.resume?.work_format?.id || 0);
        setResumeLanguages(store?.resume?.languages?.map(language => language.id).filter((id): id is number => id !== null) || []);
    }, []);

    const optionsResumeName = [
        { value: 'Веб-программист frontend', label: 'Веб-программист frontend' },
        { value: 'Веб-программист backend', label: 'Веб-программист backend' },
        { value: 'DevOps', label: 'DevOps' },
        { value: 'Мобильный разработчик', label: 'Мобильный разработчик' },
        { value: 'UI/UX дизайнер', label: 'UI/UX дизайнер' },
        { value: 'Системный администратор', label: 'Системный администратор' },
        { value: 'Аналитик данных', label: 'Аналитик данных' },
        { value: 'Бизнес-аналитик', label: 'Бизнес-аналитик' },
        { value: 'Тестировщик', label: 'Тестировщик' },
        { value: 'Руководитель проекта', label: 'Руководитель проекта' },
        { value: 'Архитектор ПО', label: 'Архитектор ПО' },
        { value: 'Разработчик игр', label: 'Разработчик игр' },
        { value: 'Инженер по данным', label: 'Инженер по данным' },
        { value: 'Копирайтер', label: 'Копирайтер' },
        { value: 'Маркетолог', label: 'Маркетолог' },
        { value: 'HR-менеджер', label: 'HR-менеджер' },
        { value: 'Финансовый аналитик', label: 'Финансовый аналитик' },
        { value: 'Менеджер по продукту', label: 'Менеджер по продукту' },
        { value: 'Ассистент руководителя', label: 'Ассистент руководителя' },
        { value: 'Инженер-электроник', label: 'Инженер-электроник' },
        { value: 'Инженер-механик', label: 'Инженер-механик' },
        { value: 'Инженер-строитель', label: 'Инженер-строитель' },
        { value: 'Менеджер по продажам', label: 'Менеджер по продажам' },
        { value: 'Креативный директор', label: 'Креативный директор' },
        { value: 'Дизайнер интерьеров', label: 'Дизайнер интерьеров' },
        { value: 'Дизайнер графики', label: 'Дизайнер графики' },
        { value: 'Арт-директор', label: 'Арт-директор' },
        { value: 'Менеджер по контенту', label: 'Менеджер по контенту' },
        { value: 'Контент-менеджер', label: 'Контент-менеджер' },
        { value: 'Креативный копирайтер', label: 'Креативный копирайтер' },
        { value: 'Продюсер', label: 'Продюсер' },
        { value: 'Редактор', label: 'Редактор' },
        { value: 'Сценарист', label: 'Сценарист' },
        { value: 'Фотограф', label: 'Фотограф' },
        { value: 'Видеограф', label: 'Видеограф' },
        { value: 'Модель', label: 'Модель' },
        { value: 'Актер', label: 'Актер' },
        { value: 'Музыкант', label: 'Музыкант' },
        { value: 'Композитор', label: 'Композитор' },
        { value: 'Звукорежиссер', label: 'Звукорежиссер' },
        { value: 'Режиссер', label: 'Режиссер' },
        { value: 'Технический писатель', label: 'Технический писатель' },
        { value: 'Секретарь', label: 'Секретарь' },
        { value: 'Курьер', label: 'Курьер' },
        { value: 'Логист', label: 'Логист' },
        { value: 'Менеджер по закупкам', label: 'Менеджер по закупкам' },
        { value: 'Складской работник', label: 'Складской работник' },
        { value: 'Оператор call-центра', label: 'Оператор call-центра' },
        { value: 'Торговый представитель', label: 'Торговый представитель' },
        { value: 'Бухгалтер', label: 'Бухгалтер' },
        { value: 'Юрист', label: 'Юрист' },
        { value: 'Экономист', label: 'Экономист' },
        { value: 'Специалист по охране труда', label: 'Специалист по охране труда' },
        { value: 'Специалист по экологии', label: 'Специалист по экологии' },
        { value: 'Специалист по качеству', label: 'Специалист по качеству' },
        { value: 'Консультант', label: 'Консультант' },
        { value: 'Тренер', label: 'Тренер' },
        { value: 'Психолог', label: 'Психолог' },
        { value: 'Социальный работник', label: 'Социальный работник' },
        { value: 'Врач', label: 'Врач' },
        { value: 'Медсестра', label: 'Медсестра' },
        { value: 'Фармацевт', label: 'Фармацевт' },
        { value: 'Научный сотрудник', label: 'Научный сотрудник' },
    ];

    const optionsResumeSkills = [
        { value: 'C#', label: 'C#' },
        { value: 'Python', label: 'Python' },
        { value: 'JavaScript', label: 'JavaScript' },
        { value: 'Java', label: 'Java' },
        { value: 'C++', label: 'C++' },
        { value: 'Ruby', label: 'Ruby' },
        { value: 'PHP', label: 'PHP' },
        { value: 'Swift', label: 'Swift' },
        { value: 'HTML', label: 'HTML' },
        { value: 'CSS', label: 'CSS' },
        { value: 'SQL', label: 'SQL' },
        { value: 'NoSQL', label: 'NoSQL' },
        { value: 'React', label: 'React' },
        { value: 'AWS', label: 'AWS' },
        { value: 'Azure', label: 'Azure' },
        { value: 'SEO', label: 'SEO' },
        { value: 'SMM', label: 'SMM' },
        { value: 'Git', label: 'Git' },
        { value: 'GitHub', label: 'GitHub' },
        { value: 'Docker', label: 'Docker' },
        { value: 'Flask', label: 'Flask' },
        { value: 'Django', label: 'Django' },
        { value: 'Excel', label: 'Excel' },
        { value: 'PPC Advertising', label: 'PPC Advertising' },
        { value: 'Agile', label: 'Agile' },
        { value: 'Scrum', label: 'Scrum' },
        { value: 'Kanban', label: 'Kanban' },
        { value: 'Sales', label: 'Sales' },
        { value: 'Marketing', label: 'Marketing' },
        { value: 'Testing', label: 'Testing' },
        { value: 'Animation', label: 'Animation' },
        { value: 'Game Development', label: 'Game Development' },
        { value: 'Web Design', label: 'Web Design' },
        { value: 'E-commerce', label: 'E-commerce' },
        { value: 'Cybersecurity', label: 'Cybersecurity' },
        { value: 'Digital Marketing', label: 'Digital Marketing' },
        { value: 'Event Planning', label: 'Event Planning' },
        { value: 'Fundraising', label: 'Fundraising' },
        { value: 'Research', label: 'Research' },
        { value: 'Creativity', label: 'Creativity' },
        { value: 'Communication', label: 'Communication' },
        { value: 'Problem Solving', label: 'Problem Solving' },
        { value: 'Negotiation', label: 'Negotiation' },
        { value: 'Statistics', label: 'Statistics' },
        { value: 'Time Management', label: 'Time Management' },
        { value: 'Team Leadership', label: 'Team Leadership' },
        { value: 'Public Speaking', label: 'Public Speaking' },
        { value: 'Quality Assurance', label: 'Quality Assurance' },
    ];
    

    const [resumeName, setResumeName] = useState<string>('');
    const handleResumeNameChange = (selectedValues: string | string[]) => {
        const newName = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
        setResumeName(newName);
    };

    const [resumeSkills, setResumeSkills] = useState<string[]>([]);
    const handleResumeSkillsChange = (selectedValues: string | string[]) => {
        const newSkills = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
        setResumeSkills(newSkills);
    };

    const [resumeWorkFormat, setResumeWorkFormat] = useState<number>();
    const [resumeLanguages, setResumeLanguages] = useState<number[]>([]);

    const [errorNetwork, setErrorNetwork] = useState<string>('');
    const handlePostResumeInformation = async () => {
        try{
            await store?.postResumeInformation(resumeName, resumeSkills, resumeWorkFormat, resumeLanguages);
            setErrorNetwork('');
        }
        catch (e: any) {
            const errorMsg = e.detail && e.detail.length >= 0 ? e.detail[0].msg : 'Максимальная длина текста 20 символов';
            setErrorNetwork(errorMsg);
        } finally {
            // console.log(errorNetwork);
        }
    }


    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        
        if (event.target.checked) {
            setResumeLanguages(prev => [...prev, value]);
        } else {
            setResumeLanguages(prev => prev.filter(id => id !== value));
        }
    };

    return (
        <Box styleClass="bg-white">
            <div className="mb-4">
                <Link to={`/profile/${store?.userBriefInformation?.user_id}`} className="text-decoration-none" style={{ color: "blue", fontSize: "12px" }}> {"<"} Вернуться к профилю без сохранения</Link>
                <p className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>
                    Ключевые факты
                    <hr className="m-0"></hr>
                </p>
            </div>
            <div className="row">
                <div className="col-md-4 d-flex align-items-start">
                    <div>
                        <div className="fw-bold">Заполните резюме</div>
                        <p style={{ fontSize: "12px" }} className="text-color-gray">Данные о должности, навыках, формате работы и языках</p>
                        {errorNetwork !== '' && <span className="text-danger">{errorNetwork}</span>}
                        
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="d-flex justify-content-start">
                        <div className="w-100">
                            <div className="row d-flex mb-3">
                                <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>
                                    Должность
                                </span>
                                {/* <CustomSelect
                                    initialSelectedValues={[resumeName] ?? []}
                                    isMulti={false}
                                    // initialOptions={optionsResumeName}
                                    initialOptions={[]}
                                    onChange={handleResumeNameChange}
                                /> */}
                                <Form.Control
                                    type="text"
                                    placeholder="Введите должность"
                                    value={resumeName}
                                    onChange={(e) => setResumeName(e.target.value)}
                                    className="bg-white"
                                    style={{marginLeft: "12px"}}
                                />
                                {/* <input
                                    type="text"
                                    className="form-control"
                                    value={resumeName}
                                    onChange={(e) => setResumeName(e.target.value)}
                                /> */}
                            </div>
                            <div className="row d-flex mb-3">
                                <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>
                                    Навыки (до 5 навыков)
                                </span>
                                <CustomSelect
                                    initialSelectedValues={resumeSkills ?? []}
                                    isMulti={true}
                                    // initialOptions={optionsResumeSkills}
                                    initialOptions={[]}
                                    onChange={handleResumeSkillsChange}
                                />
                            </div>
                            <div className="row d-flex mb-3">
                                <div className="col-6">
                                    <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>
                                        Формат работы
                                    </span>
                                    <div className="ms-3">
                                        {workFormat && workFormat.map((format) => (
                                            <div className="form-check" key={format.id}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="workFormat"
                                                    id={`flexRadioDefault${format.id}`}
                                                    value={format.id ?? 1}
                                                    checked={resumeWorkFormat === format.id}
                                                    onChange={(e) => setResumeWorkFormat(Number(e.target.value))}
                                                />
                                                <label className="form-check-label" htmlFor={`flexRadioDefault${format.id}`}>
                                                    {format.name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-6">
                                    <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>
                                        Языки
                                    </span>
                                    <LanguageSelector onCitySelect={setResumeLanguages} initialLanguages={store?.resume?.languages as Language[]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        className="btn-violet large"
                        onClick={() => handlePostResumeInformation()}>
                        Сохранить
                    </button>
                </div>
            </div>
        </Box>
    );
}
