import { Link } from "react-router-dom";
import { VacancyBriefInformation } from "../api/models/response/VacancyResponse";
import { useNavigate } from "react-router-dom";
import { BoxMini } from "./Box";

export default function VacancyComponentNew({ vacancy, companyAvatar}: { vacancy: VacancyBriefInformation, companyAvatar?: boolean}) {
    const navigate = useNavigate(); // Получите доступ к navigate
    
    const handleVacancyView = (id: number | null) => {
        if (id !== null) { // Проверка на null
            navigate(`/vacancy/${id}`); // Перенаправление на страницу редактирования с id
        }
    };

    const handleCompanyView = (id: number | null) => {
        if (id !== null) { // Проверка на null
            navigate(`/company/${id}`); // Перенаправление на страницу редактирования с id
        }
    };

    return (
        <BoxMini styleClass={companyAvatar ? "bg-white" : "bg-gray"}>
            <div className="row align-items-start">
                <div className="d-flex justify-content-end d-sm-none">
                        <div className="d-flex align-items-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM13 3H3V13H13V3Z" fill="#141414" fillOpacity="0.7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M11 1C11.2761 1 11.5 1.22386 11.5 1.5V3.5C11.5 3.77614 11.2761 4 11 4C10.7239 4 10.5 3.77614 10.5 3.5V1.5C10.5 1.22386 10.7239 1 11 1Z" fill="#141414" fillOpacity="0.7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M5 1C5.27614 1 5.5 1.22386 5.5 1.5V3.5C5.5 3.77614 5.27614 4 5 4C4.72386 4 4.5 3.77614 4.5 3.5V1.5C4.5 1.22386 4.72386 1 5 1Z" fill="#141414" fillOpacity="0.7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 5.5C2 5.22386 2.22386 5 2.5 5H13.5C13.7761 5 14 5.22386 14 5.5C14 5.77614 13.7761 6 13.5 6H2.5C2.22386 6 2 5.77614 2 5.5Z" fill="#141414" fillOpacity="0.7" />
                            </svg>
                            <span className="opacity-50 ms-1">{vacancy.date_create ? new Date(vacancy.date_create).toLocaleDateString() : ""}</span>
                        </div>
                    </div>
                { companyAvatar && <div className="col-auto">
                    {
                        vacancy.logo_image ? 
                        <img src={vacancy.logo_image} style={{ width: "90px", borderRadius: "10px", objectFit: "cover" }} alt="Company logo" />
                        :
                        <img src='/images/icons/company/company-default.png' style={{ width: "90px", borderRadius: "10px", objectFit: "cover" }} alt="Company logo" />
                    }
                </div>}
                <div className="col position-relative">
                    <div className="position-absolute top-0 end-0 pe-2 d-none d-sm-flex">
                        <div className="d-flex align-items-center">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM13 3H3V13H13V3Z" fill="#141414" fillOpacity="0.7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M11 1C11.2761 1 11.5 1.22386 11.5 1.5V3.5C11.5 3.77614 11.2761 4 11 4C10.7239 4 10.5 3.77614 10.5 3.5V1.5C10.5 1.22386 10.7239 1 11 1Z" fill="#141414" fillOpacity="0.7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M5 1C5.27614 1 5.5 1.22386 5.5 1.5V3.5C5.5 3.77614 5.27614 4 5 4C4.72386 4 4.5 3.77614 4.5 3.5V1.5C4.5 1.22386 4.72386 1 5 1Z" fill="#141414" fillOpacity="0.7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M2 5.5C2 5.22386 2.22386 5 2.5 5H13.5C13.7761 5 14 5.22386 14 5.5C14 5.77614 13.7761 6 13.5 6H2.5C2.22386 6 2 5.77614 2 5.5Z" fill="#141414" fillOpacity="0.7" />
                            </svg>
                            <span className="opacity-50 ms-1">{vacancy.date_create ? new Date(vacancy.date_create).toLocaleDateString() : ""}</span>
                        </div>
                    </div>
                    {
                        companyAvatar && <h5 onClick={() => handleCompanyView(vacancy.company_id)} style={{ cursor: "pointer" }}>{vacancy.name_company}</h5>
                    }
                    
                    <h3 className="mb-2" onClick={() => handleVacancyView(vacancy.id)} style={{ cursor: "pointer" }}>{vacancy.name_post}</h3>
                    <div className="row row-cols-auto gap-2 opacity-75 mb-2">
                        <div className="d-flex align-items-center">
                            <img className="me-2" src="/images/icons/location-dark.png" alt="Location" />
                            <span>{vacancy.city}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <img className="me-1" src="/images/icons/time.png" alt="Time" />
                            <span>{vacancy.work_format}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <img className="me-1" src="/images/icons/money.png" alt="Salary" />

                            { vacancy.salary_to === 0 && vacancy.salary_from === 0 ?
                                <span> зарплата не указана</span>
                                :
                                <span> { vacancy.salary_from !== 0 && `от ${vacancy.salary_from}`} {vacancy.salary_to !== 0 && `до ${vacancy.salary_to}`} ₽/мес</span>
                            }
                        </div>
                        <div className="d-flex align-items-center">
                            <img className="me-1" src="/images/icons/money.png" alt="Area" />
                            <span>{vacancy.direction}</span>
                        </div>
                    </div>
                    <span className="opacity-75">{vacancy.desc}</span>
                </div>
            </div>
        </BoxMini>
    );
}
