import { useEffect, useState } from "react";
import GetGeneralInformationService, { WorkFormat } from "../api/services/GetGeneralInformationService";
import Select from 'react-select';


export interface Props{
    onFormatSelect: (formatId: number | null) => void
    initialFormat?: WorkFormat;
    styleClass?: string;
    initialFormatId?: number | null
}

const FormatSelector: React.FC<Props> = function({ onFormatSelect: onFormatSelect,  initialFormat: initialFormat, styleClass, initialFormatId}) {
    const [formats, setFormats] = useState<WorkFormat[]>([]);
    const [formatId, setFormatId] = useState<number | null>(initialFormat?.id ?? null);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const fetchData = async () => {
            try{
                const response = await GetGeneralInformationService.GetWorkFormats();
                setFormats(response.data);
            }
            catch(error){
                // console.log("Ошибка при загрузке данных:", error);
            };
        };
        fetchData();
    }, [])

    useEffect(() => {
        setFormatId(initialFormatId ?? null);
    }, [initialFormatId]);

    const handleFormatChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedFormatId = parseInt(selectedOption.value);
            setFormatId(selectedFormatId);
            onFormatSelect(selectedFormatId); // Вызываем функцию для обработки выбора
        } else {
            setFormatId(null);
            onFormatSelect(null);
        }
    };

    const handleInputChange = (inputValue: string) => {
        setSearchTerm(inputValue);
    };

    const formatOptions = formats.map(direction => ({
        value: direction.id?.toString() ?? '',
        label: direction.name ?? '',
    }));

    return (
        <>
        {styleClass === "bg-gray" ?
            <Select
            isClearable
            options={formatOptions}
            className={`basic-multi-select me-0 ${styleClass}`}
            classNamePrefix="select"
            value={formatOptions.find(option => option.value === formatId?.toString()) ?? null}
            onChange={handleFormatChange}
            onInputChange={handleInputChange}
            placeholder="Формат"
            noOptionsMessage={() => 'Ничего не найдено'}
            styles={{
                control: (base) => ({
                    ...base,
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: '#f0f0f0',
                    position: 'relative',
                    // zIndex: 1,
                    pointerEvents: 'auto',
                    marginTop: '1px',
                    marginBottom: '1px',    
                })
            }}
        />
            :
            <Select
            isClearable
            options={formatOptions}
            className={`basic-multi-select me-0 ${styleClass} `}
            classNamePrefix="select"
            value={formatOptions.find(option => option.value === formatId?.toString())}
            onChange={handleFormatChange}
            onInputChange={handleInputChange}
            placeholder="Формат"
            noOptionsMessage={() => 'Ничего не найдено'}
            /> 

        }
        </>
    );
}

export default FormatSelector;
