import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface CodeInputProps {
  onCodeChange: (code: string) => void;
  onClick: () => void;
  email?: string;
  error?: string | null;
  companyRegistration?: boolean | null;
  onChangeEmailClick?: () => void
}

const CodeInput: React.FC<CodeInputProps> = ({ onCodeChange, email, onClick, error, companyRegistration, onChangeEmailClick }) => {
  const navigate = useNavigate();
  const [code, setCode] = useState(["", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      setErrorPassword(error);
    }
  }, [error]);


  useEffect(() => {
    // Устанавливаем фокус на первую ячейку при монтировании компонента
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      onCodeChange(newCode.join("")); // Call the prop function with the new code
      if (index < 3) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace") {
      if (code[index] === "") {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
      } else {
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
        onCodeChange(newCode.join("")); // Call the prop function with the new code
      }
    }

    // Обработка нажатия клавиши Enter
    if (e.key === "Enter") {
      e.preventDefault(); // Предотвращаем отправку формы
      onClick(); // Вызываем функцию onClick
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const pasteData = e.clipboardData.getData("Text");
    if (/^\d{4}$/.test(pasteData)) {
      e.preventDefault();
      const newCode = pasteData.split("").slice(0, 4);
      setCode(newCode);
      onCodeChange(newCode.join("")); // Call the prop function with the new code
      // Устанавливаем фокус на следующую ячейку, если нужно
      inputRefs.current[3]?.focus();
    }
  };

  return (
    <>
      {
        companyRegistration && companyRegistration===true ?
        (
          <div className="mb-2">
          <div className="mb-4">
            <h3 className="fw-bold">Введите код, отправленный на почту</h3>
            <p className="">Мы отправили код на адрес <strong>{email}</strong>.<br />Введите код для подтверждения электронной почты.</p>
            {errorPassword && <div className="text-danger mt-0 pt-0">{errorPassword}</div>} {/* Отображаем ошибку, если она есть */}
          </div>
          <div className="w-100">
              <div className="d-flex justify-content-center mb-4">
                  {code.map((digit, index) => (
                      <input
                          key={index}
                          type="number"
                          className={`form-control square text-center code-input ${digit && "filled"}`}
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onPaste={(e) => handlePaste(e, index)}
                          ref={el => inputRefs.current[index] = el}
                      />
                  ))}
              </div>
              <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                  <button className="btn-violet large w-75" onClick={onClick}>Продолжить</button>
              </div>
              <div className="d-flex justify-content-center text-center mt-3" style={{ width: '90%' }}>
                  <p className="m-0" style={{fontSize: "15px"}}>Нажимая "Продолжить", вы принимаете <span className="text-decoration-underline fw-bold" style={{ cursor: 'pointer' }} onClick={() => { navigate('/userAgreement'); }}>пользовательское соглашение</span> и <span className="text-decoration-underline fw-bold" style={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy'); }}> политику обработки персональных данных</span> </p>
              </div>
          </div>
        </div>
        ) : (
          <div className="mb-2 ms-0 ps-0">
            <div className="mb-4">
              <h3 className="fw-bold">Введите код, отправленный на почту</h3>
              <p className="m-0">Мы отправили код на адрес <strong>{email}</strong>.<br />Введите код для подтверждения электронной почты.</p>
              {
                onChangeEmailClick && 
                <a
                    className="text-decoration-underline border-0 mb-2 text-black text-center "
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        onChangeEmailClick();
                    }}
                >
                    Нажмите, чтобы изменить адрес электронной почты.
                </a>
              }
              {errorPassword && <div className="text-danger mt-0 pt-0">{errorPassword}</div>} {/* Отображаем ошибку, если она есть */}
            </div>
            <div className="w-100">
                <div className="d-flex justify-content-start mb-4">
                    {code.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            className={`form-control square text-center code-input ${digit && "filled"}`}
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={(e) => handlePaste(e, index)}
                            ref={el => inputRefs.current[index] = el}
                        />
                    ))}
                </div>
                <div className="d-flex justify-content-start" style={{ width: '100%' }}>
                    <button className="btn-violet large w-100" onClick={onClick} style={{flexGrow: 1,maxWidth: '600px' }}>Продолжить</button>
                </div>

                <div className="d-flex justify-content-center text-center mt-3" style={{ width: '90%' }}>
                    <p className="m-0" style={{fontSize: "15px", opacity: "0.7"}}>Нажимая "Продолжить", вы принимаете <span className="text-decoration-underline fw-bold" style={{ cursor: 'pointer' }} onClick={() => { window.open('/userAgreement', '_blank'); }}>пользовательское соглашение</span> и <span className="text-decoration-underline fw-bold" style={{ cursor: 'pointer' }} onClick={() => { window.open('/privacy', '_blank'); }}> политику обработки персональных данных</span> и соглашение на использование куки </p>
                </div>
            </div>
          </div>
        )
      }

    </>
  );
};

export default CodeInput;
