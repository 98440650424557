import { Form } from "react-bootstrap";
import { Box } from "../../../components/Box";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../App";
import CitySelector from "../../../components/CitySelector";
import { Link } from "react-router-dom";

export function EditCompanyInformation() {
    useEffect(() => {
        document.title = "Редактирование информации о компании";
    }, []);

    const store = useContext(Context);
    const [cityId, setCityId] = useState<number | null>();
    const [companyName, setCompanyName] = useState<string>('');
    const [about, setAbout] = useState<string>('');
    const [photo_url, setPhotoUrl] = useState<string | null>('');
    const [socialNetworkUrl, setSocialNetworkUrl] = useState<string>('');
    const [avatarPicture, setAvatarPicture] = useState<File | null>(null);

    const [error, setError] = useState<string | undefined>(undefined);
    const [requestError, setRequestError] = useState<string | undefined>(undefined);
    const [imageError, setImageError] = useState<string | undefined>();

    useEffect(() => {
        setCityId(store?.company?.city?.id ? store?.company?.city?.id : 0);
        setCompanyName(store?.company?.name_company ? store?.company?.name_company : '');
        setPhotoUrl(store?.company?.avatar ? store?.company?.avatar : '');
        setAbout(store?.company?.about ? store?.company?.about : '');
        setSocialNetworkUrl(store?.company?.social_network_url ? store?.company?.social_network_url : '');
    }, [store])

    const handleSubmit = async()=>{
        if(!cityId || !companyName || !about){
            setError('Пожалуйста, заполните поле.');
            return;
        }

        try{
            avatarPicture && await store?.postUpdateCompanyAvatar(avatarPicture);
            await store?.putCompanyInformation(cityId, companyName, about, socialNetworkUrl);
        } catch(e: any){
            setRequestError(e.response?.data?.message);
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;

        if (file) {
            const maxSizeInMB = 2; // Максимальный размер в мегабайтах
            const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Переводим в байты

            // Проверяем размер файла
            if (file.size > maxSizeInBytes) {
                setError('Файл должен быть меньше 2 МБ.');
                setAvatarPicture(null);
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                if (img.width > 2000 || img.height > 2000) {
                    setImageError('Изображение должно быть не больше 2000x2000 пикселей.');
                    setAvatarPicture(null);
                } else {
                    setImageError(undefined);
                    setAvatarPicture(file);
                }
            };

            img.onerror = () => {
                setImageError('Ошибка загрузки изображения.');
                setAvatarPicture(null);
            };
        }
    };

    return(
    <div className="container">
    <Box styleClass="bg-white">
        <div className="mb-4">
            <Link to={`/company/${store?.userBriefInformation?.company_id}`} className=" text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться к профилю без сохранения</Link>
            <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                Основная информация о себе
                <hr className="m-0"></hr>
            </p>
        </div>
        <div className="row mb-4">
          <div className="col-md-4 d-flex align-items-center">
              <div>
                  <div className="fw-bold">Изображение компании</div>
                  <p style={{fontSize:"12px"}} className="text-color-gray">Это изображение будет публично демнострироваться в качестве изображения вашей компании</p>
                  <span className="text-danger">{requestError}</span>
              </div>
          </div>
          <div className="col-md-8">
              <div className="d-flex justify-content-center align-items-center flex-wrap">
                {
                        
                        photo_url ? 
                        <div className="me-3" style={{ position: 'relative', display: 'inline-block' }}>
                            <img 
                                src={photo_url} 
                                className="rounded-circle" 
                                style={{ height: "186px", width: "186px", objectFit: "cover" }} 
                                alt="Profile" 
                            />
                            <button 
                                onClick={store?.deleteCompanyAvatar} 
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    background: 'none',
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: '#4640DE',
                                    fontSize: '20px',
                                    padding: '0',
                                    lineHeight: '1'
                                }}
                                aria-label="Удалить изображение"
                            >
                                <span style={{
                                    display: 'inline-block',
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #4640DE',
                                    borderRadius: '50%',
                                    position: 'relative',
                                    background: 'transparent'
                                }}>
                                    <span style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '12px',
                                        height: '2px',
                                        background: '#4640DE',
                                        transform: 'translate(-50%, -50%) rotate(45deg)'
                                    }}></span>
                                    <span style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        width: '12px',
                                        height: '2px',
                                        background: '#4640DE',
                                        transform: 'translate(-50%, -50%) rotate(-45deg)'
                                    }}></span>
                                </span>
                            </button>
                        </div>
                        :
                        <img src="/images/icons/company/company-default.png" className="me-3 rounded-circle" style={{height: "186px", width: "186px", objectFit: "cover"}} alt="Profile" />
                    }
                    <div className="file-upload-container flex-wrap">
                            <Form.Group controlId="formFile" className="" onChange={handleFileChange}>
                                <div className="file-upload w-100">
                                    <Form.Label htmlFor="fileInput" className="custom-file-label w-100 p-4">
                                        {avatarPicture ? (
                                            <div className="uploaded-image-container">
                                                <img
                                                    src={URL.createObjectURL(avatarPicture)}
                                                    alt="Uploaded"
                                                    className="uploaded-image"
                                                />
                                                <div className="upload-text">Изображение загружено</div>
                                            </div>
                                        ) : (
                                            <>
                                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_718_357)">
                                                        <path d="M20.5 10.6666H20.5133" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M23.1663 5.33337H9.83301C7.62387 5.33337 5.83301 7.12423 5.83301 9.33337V22.6667C5.83301 24.8758 7.62387 26.6667 9.83301 26.6667H23.1663C25.3755 26.6667 27.1663 24.8758 27.1663 22.6667V9.33337C27.1663 7.12423 25.3755 5.33337 23.1663 5.33337Z" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M5.83301 20.0001L11.1663 14.6667C11.7744 14.0816 12.4642 13.7736 13.1663 13.7736C13.8685 13.7736 14.5583 14.0816 15.1663 14.6667L21.833 21.3334" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M19.167 18.6667L20.5003 17.3334C21.1084 16.7482 21.7982 16.4402 22.5003 16.4402C23.2025 16.4402 23.8922 16.7482 24.5003 17.3334L27.167 20" stroke="#4640DE" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_718_357">
                                                            <rect width="32" height="32" fill="white" transform="translate(0.5)"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <div className="upload-text">Нажмите или перетащите</div>
                                                <div className="upload-subtext">SVG, PNG, JPG или GIF (максимум 2000 x 2000 пикселей)</div>
                                            </>
                                        )}
                                        <Form.Control 
                                            type="file" 
                                            id="fileInput" 
                                            className="custom-file-input w-100" 
                                            accept="image/*" 
                                        />
                                    </Form.Label>
                                </div>
                            </Form.Group>
                        </div>
              </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
            <div className="col-md-4 d-flex align-items-start">
                <div>
                    <div className="fw-bold">Информация о вашей компании</div>
                    <p style={{fontSize:"12px"}} className="text-color-gray">Основная информация о компании</p>
                </div>
            </div>
                <div className="col-md-8">
                    <div className="d-flex justify-content-start flex-column">
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                Название компании
                            </span>
                            <Form.Control
                                type="text"
                                onChange={(e) => setCompanyName(e.target.value)}
                                placeholder="Введите название компании"
                                value={companyName}
                                className="bg-white"
                            />
                            {!companyName && <span className="text-danger">{error}</span>}
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                Ссылка на сайт компании
                            </span>
                            <Form.Control
                                type="text"
                                onChange={(e) => setSocialNetworkUrl(e.target.value)}
                                placeholder="Введите название компании"
                                value={socialNetworkUrl}
                                className="bg-white"
                            />
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                Город
                            </span>
                            <CitySelector onCitySelect={setCityId} initialCity={store?.company?.city ?? undefined} />
                            {!cityId && <span className="text-danger">{error}</span>}
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                Коротко о компании
                            </span>
                            <textarea 
                            className="form-control bg-white m-0" 
                            id="about_company" 
                            rows={5}
                            placeholder="Напишите информацию о компании" 
                            onChange={(e) => setAbout(e.target.value)}
                            value={about} ></textarea>
                            {!about && <span className="text-danger">{error}</span>}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                        <button 
                            className="btn-violet large" 
                            onClick={() => { handleSubmit()}}>
                            Сохранить 
                        </button>
                    </div>
        </div>
    </Box>
    </div>
  )
}