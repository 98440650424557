import VacancyComponentNew from "../components/VacancyComponentNew";
import { useEffect, useRef, useState } from "react";
import { VacancyBriefInformation } from "../api/models/response/VacancyResponse";
import VacancyService from "../api/services/VacancyService";
import GetGeneralInformationService, { City, Direction, WorkFormat } from "../api/services/GetGeneralInformationService";
import CitySelector from "../components/CitySelector";
import { useNavigate } from "react-router-dom";
import { BoxMini } from "../components/Box";
import DirectionSelector from "../components/DirectionSelector";
import FormatSelector from "../components/FormatSelector";

export function Vacancys() {
    
    useEffect(() => {
        document.title = "Вакансии";
    }, []);

    const [vacancys, setVacancys] = useState<VacancyBriefInformation[]>([]);
    const [reset, setReset] = useState<boolean>(false);
    const debounceTimeout = useRef<number | null>(null);
    
    const [workFormatFilter, setWorkFormatFilter] = useState<number | null>(null);
    const [cityFilter, setCityFilter] = useState<number | null>(null);
    const [directionFilter, setDirectionFilter] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchField, setSearchField] = useState<string>('');
    const [fetching, setFetching] = useState<boolean>(false);
    const [endOfScroll, setEndOfScroll] = useState<boolean>(false);
    

    // Загрузка форматов работы и направлений при монтировании компонента
    useEffect(() => {
        document.title = "Вакансии";
        loadVacancies(1);
    }, []);

    // Загрузка вакансий при изменении страницы
    useEffect(() => {
        if (fetching) {
            loadVacancies(currentPage);
        }
    }, [currentPage]);

    // Обработчик скролла для подгрузки данных
    useEffect(() => {
        const handleScroll = () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }

            debounceTimeout.current = window.setTimeout(() => {
                if (window.innerHeight + document.documentElement.scrollTop + 80 >= document.documentElement.scrollHeight && !endOfScroll) {
                    setFetching(true);
                    setCurrentPage(prevPage => prevPage + 1);
                }
            }, 200); // Задержка в 300 мс
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
            window.removeEventListener('scroll', handleScroll);
        };
    }, [fetching, endOfScroll]);

    // Функция загрузки вакансий
    const loadVacancies = async (page: number) => {
        try {
            const response = await VacancyService.GetBriefInformation(
                cityFilter ?? null,
                workFormatFilter ?? null,
                directionFilter ?? null,
                searchField,
                page,
                10
            );
            setVacancys(prevVacancys => {
                const newVacancys = response.data;
                const uniqueVacancys = [...prevVacancys, ...newVacancys].filter((vacancy, index, self) =>
                    index === self.findIndex((c) => c.id === vacancy.id)
                );
                if (newVacancys.length === 0) {
                    setEndOfScroll(true);
                }
                return uniqueVacancys;
            });
        } catch (error) {
            // console.error("Ошибка при загрузке вакансий:", error);
        }
        finally{
            setFetching(false);
        }
    };

    // Применение фильтров
    const applyFilters = () => {
        setEndOfScroll(false);
        setVacancys([]);
        setCurrentPage(1);
        loadVacancies(1);
    };

    // Сброс фильтров
    const resetFilters = () => {
        setWorkFormatFilter(null);
        setCityFilter(null);
        setDirectionFilter(null);
        setSearchField('');
        setReset(!reset);
    };

    // Загрузка вакансий при изменении фильтров
    useEffect(() => {
        setEndOfScroll(false);
        setVacancys([]);
        setCurrentPage(1);
        loadVacancies(1);
    }, [reset]);

    return (
    <div className="container">
        <div className="d-flex row">
            <div className="col-md-4 col-lg-3 h-100 mb-3"> 
                <div className="p-3 bg-white h-100" style={{borderRadius: "0.375rem"}}> 
                    <div className="input-group" style={{height: "42px"}}>
                        <span className="input-group-text" id="search" >
                            <img className="" src="./images/icons/search.png" alt="Search" style={{ width: "24px", height: "24px" }} />
                        </span>
                        <input 
                            type="text" 
                            className="form-control input-vacancys" 
                            placeholder="Поиск" 
                            aria-label="Search" 
                            aria-describedby="search"
                            style={{ borderLeftWidth: "2px", outline: 'none', backgroundColor: "#f3f3f4 !important" }}
                            value={searchField}
                            // onFocus={(e) => {
                            //     e.target.style.backgroundColor = "#e5e8eb"
                            // }}
                            onChange={(e) => setSearchField(e.target.value)} 
                        />
                    </div>

                    <div className="mt-2 w-100 d-flex">
                        <span className="input-group-text h-auto" id="city" style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0', height: '38px' }}>
                            <div className="" style={{ width: "24px", height: "24px" }}>
                                <img src="./images/icons/location.png" style={{ width: "auto", height: "24px" }} alt="city" />
                            </div>
                        </span>
                        <div className={`form-control m-0 p-0 h-auto custom-border-radius`} style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', height: '38px' }}>
                            <CitySelector 
                                onCitySelect={setCityFilter}
                                initialCityId={cityFilter}
                                styleClass="bg-gray" 
                                aria-label="city" 
                                aria-describedby="city" 
                            />
                        </div>
                    </div>

                    <div className="mt-2 w-100 d-flex">
                        <span className="input-group-text h-auto" id="direction" style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0', height: '38px' }}>
                            <img src="./images/icons/type-work.png" style={{ width: "24px", height: "24px" }} alt="workFormat" />
                        </span>
                        <div className={`form-control m-0 p-0 h-auto custom-border-radius`} style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', height: '38px' }}>
                            <FormatSelector onFormatSelect={setWorkFormatFilter} initialFormatId={workFormatFilter} styleClass="bg-gray"/>
                        </div>
                    </div>

                    <div className="mt-2 w-100 d-flex">
                        <span className="input-group-text h-auto" id="direction" style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0', height: '38px' }}>
                            <img src="./images/icons/direction.png" style={{ width: "24px", height: "24px" }} alt="direction" />
                        </span>
                        <div className={`form-control m-0 p-0 h-auto custom-border-radius`} style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', height: '38px' }}>
                            <DirectionSelector onDirectionSelect={setDirectionFilter} initialDirectionId={directionFilter} styleClass="bg-gray" aria-label="direction" aria-describedby="direction" />
                        </div>
                    </div>

                    <div className="w-100 mt-2">
                        <button className="btn-violet medium w-100" type="button" onClick={applyFilters} style={{borderRadius:"0.375rem"}}>Применить</button>
                    </div>

                    <div className="w-100 mt-2">
                        <button className="btn-gray medium w-100" type="button" onClick={resetFilters} style={{borderRadius:"0.375rem"}}>Сбросить</button>
                    </div>
                </div>
            </div>

            {vacancys.length > 0 ? 
            (
                <div className="col-12 col-md-8 col-lg-9">
                    {vacancys.map(vacancy => (
                        <VacancyComponentNew
                            key={vacancy.id}
                            vacancy={vacancy} // Передаем объект vacancy
                            companyAvatar={true}
                        />
                    ))}
                </div>
            )
            :
            (   
                <div className="col-12 col-md-8 col-lg-9">
                    <BoxMini styleClass="d-flex justify-content-center align-items-center bg-white col-12">
                        <div className="d-flex justify-content-center align-items-center">
                            <p className="text-center">По вашему запросу ничего не найдено</p>
                        </div>
                    </BoxMini>
                </div>
            )
            }
        </div>
    </div>
    );
}
