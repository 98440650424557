import React, { useEffect, useState } from "react";
import { University } from "../api/models/Education"
import GetGeneralInformationService from "../api/services/GetGeneralInformationService";
import Select from 'react-select';



interface UniversitySelectorProps {
    onUniversityChange: (universityId: number) => void;
    initialUniversity?: University;
    itemsPerPage?: number;
}

const UniversitySelector:React.FC<UniversitySelectorProps> = ({onUniversityChange, initialUniversity, itemsPerPage = 10}) => {
    const [universityId, setUniversityId] = useState<number>(initialUniversity?.id || 0);
    const [universities, setUniversities] = useState<University[]>([]);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [endOfScroll, setEndOfScroll] = useState<boolean>(false);
    const [lastExistedPage, setLastExistedPage] = useState<number>(1);


    useEffect(() => {
        if (initialUniversity) {
            const fetchInitialUniresity = async () => {
                setLoading(true);
                try {
                    const response = await GetGeneralInformationService.GetUniversitys(initialUniversity?.name ?? '');
                    setUniversities(prevUniresitys => {
                        const newUniresitys = response.data;
                        const uniqueUniresitys = [...prevUniresitys, ...newUniresitys].filter((city, index, self) =>
                            index === self.findIndex((c) => c.id === city.id)
                        );
                        return uniqueUniresitys;
                    });
                    setUniversityId(initialUniversity?.id ?? 0);
                } catch (error) {
                    // console.error("Ошибка при загрузке университетов:", error);
                } finally {
                    setLoading(false);
                }
            };
            fetchInitialUniresity();
        }
        const fetchUniresitys = async () => {
            setLoading(true);
            try {
                const response = await GetGeneralInformationService.GetUniversitys('', 1, 10);
                setUniversities(prevUniresitys => {
                    const newUniresitys = response.data;
                    const uniqueUniresitys = [...prevUniresitys, ...newUniresitys].filter((city, index, self) =>
                        index === self.findIndex((c) => c.id === city.id)
                    );
                    return uniqueUniresitys;
                });
            } catch (error) {
                // console.error("Ошибка при загрузке городов:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUniresitys();
    }, [])

    useEffect(() => {
        const fetchUniversitys = async () => {
            if (loading) return;
            setLoading(true);
            try {
                const response = await GetGeneralInformationService.GetUniversitys(searchTerm, currentPage, itemsPerPage);
                setUniversities(prevUniresitys => {
                    const newUniresitys = response.data;
                    const uniqueUniresitys = [...prevUniresitys, ...newUniresitys].filter((city, index, self) =>
                        index === self.findIndex((c) => c.id === city.id)
                    );
                    if(newUniresitys.length === 0 && currentPage > lastExistedPage) {
                        setLastExistedPage(currentPage);
                        setEndOfScroll(true);
                        // console.log(endOfScroll);
                    }
                    return uniqueUniresitys;
                });
            } catch (error) {
                // console.error("Ошибка при загрузке городов:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchUniversitys();
    }, [currentPage, searchTerm]);
    
    const handleUniversityChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            const selectedUniversityId = parseInt(selectedOption.value);
            setUniversityId(selectedUniversityId);
            onUniversityChange(selectedUniversityId); // Вызываем функцию для обработки выбора
        } else {
            // console.log('Университет не выбран'); // Выводим сообщение, если город не выбран
            setUniversityId(0);
        }
    };


    const handleInputChange = (inputValue: string) => {
        setSearchTerm(inputValue);
        setCurrentPage(1); // Сбросить страницу при изменении поиска
    };

    const handleScroll = (event: WheelEvent | TouchEvent) => {
        const target = event.target as HTMLDivElement;
        if (target.scrollHeight - target.scrollTop <= target.clientHeight + 10 && !loading) {
            setCurrentPage(prevPage => prevPage + 1); // Загружаем следующую страницу
        }
    };

    const universityOptions = universities.map(university => ({
        value: university.id?.toString() ?? '',
        label: university.name ?? '',
    }));

    return (
    <>
        <Select
            isClearable
            options={universityOptions}
            className="basic-multi-select w-100 me-0"
            classNamePrefix="select"
            value={universityOptions.find(option => option.value === universityId?.toString()) ?? null}
            onChange={handleUniversityChange}
            onInputChange={handleInputChange}
            placeholder="Выберите университет..."
            onMenuScrollToBottom={handleScroll}
            noOptionsMessage={()=> "Такого университета нет в базе данных."}
            />
    </>
    )
}

export default UniversitySelector;