
interface TagsProps{
    tag: string;
    colorClass?: string;
    styleClass?: string;
}

export function Tag({tag, colorClass, styleClass}: TagsProps){

    const style = styleClass ?? "bg-gray";
    colorClass = colorClass ?? "#f3f3f4";

    return(
        <>
        <div className={"px-lg-3 py-lg-1 px-sm-2 py-sm-1 px-md-3 py-md-1 d-flex justify-content-center align-items-center" + style} 
            style={{borderRadius: "5px", 
            backgroundColor: colorClass,
            minWidth: '50px', 
            whiteSpace: 'nowrap', 
            }}> 
            <span>{tag}</span>
        </div>
        </>
    )
}
