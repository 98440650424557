import { AxiosResponse } from "axios";
import { $anon_api, $api } from "../http";
import { AvailableVacancy, VacancyBriefInformation, VacancyFullInformation, VacancyFullInformationResponse, VacancyRespondedResponse } from "../models/response/VacancyResponse";

export default class VacancyService {

    static async GetBriefInformation(
        city: number | null,
        work_format: number | null,
        direction: number | null,
        search_field: string = '',
        page: number = 1,
        quantity: number = 10
    ): Promise<AxiosResponse<VacancyBriefInformation[]>> {
        const params = {
            city: city !== 0 ? city : '',
            work_format: work_format !== 0 ? work_format : '',
            direction: direction !== 0 ? direction : '',
            search_field: search_field || '', // оставляем пустую строку, если search_field пустой
            page,
            quantity
        };
    
        return await $anon_api.get('/user/vacancy/brief', { params });
    }
    
    static async GetFullInformation(id: number): Promise<AxiosResponse<VacancyFullInformation>>{
        return await $api.get('/user/vacancy/full_info', { params: { _id: id } })
    }
    
    static async PostRespondVacancy(id: number): Promise<AxiosResponse<string>> {
        // Проверяем, что id передан
        if (id === undefined || id === null) {
            throw new Error("ID is required");
        }
    
        return await $api.post('/user/vacancy/respond', { id: id });
    }

    static async PostCreateVacancy(city_id: number, work_format_id: number , direction_id: number, name: string, desc: string, requirements: string, skills: string[], salary_from: number | null, salary_to: number | null) {
        return await $api.post('/company/vacancy/new', { city_id, work_format_id, direction_id, name, desc, requirements, skills, salary_from, salary_to });
    }

    static async GetVacancyById(v_id: number): Promise<AxiosResponse<VacancyFullInformationResponse>> {
        return await $api.get(`/company/vacancy/info/${v_id}`)
    }

    static async PutCreateVacancy(v_id: number, city_id: number, work_format_id: number, direction_id: number, name: string, desc: string, requirements: string, skills: string[], salary_from: number | null, salary_to: number | null) {
        return await $api.put(`/company/vacancy/info/${v_id}`, { city_id, work_format_id, direction_id, name, desc, requirements, skills, salary_from, salary_to });
    }

    static async deleteVacancy(id: number): Promise<AxiosResponse<string>> {
        const response = await $api.delete('/company/vacancy/info', {
            data:  {id},
        });
        return response;
    }

    static async getAvailableVacancies( page: number = 1, quantity: number = 10): Promise<AxiosResponse<AvailableVacancy[]>> {
        const params = {
            page: page,
            quantity: quantity
        }
        return await $api.get('/company/vacancy/info_brief', { params: params })
    }

    static async getCompanyVacanciesBriefInfo(company_id: number, page: number = 1, quantity: number = 10): Promise<AxiosResponse<VacancyBriefInformation[]>> {
        const params = { 
            company_id: company_id,
            page: page, 
            quantity: quantity
        }
        return await $api.get('/user/vacancy/brief/company', { params: params })
    }

    static async getVacancyResponds(vacancy_id: number, page: number = 1, quantity: number = 10): Promise<AxiosResponse<VacancyRespondedResponse[]>> {
        const params = {
            page: page,
            quantity: quantity
        }
        return await $api.get(`/company/vacancy/responded/${vacancy_id}`, { params: params })
    }

    static async showAllInfo(): Promise<AxiosResponse<boolean>> {
        return await $api.get('/company/show_all_info')
    }
}

