import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../api/services/AuthService"; // Исправлено название на AuthService
import { Form } from "react-bootstrap";
import CodeInput from "../components/CodeInput";
import { Context } from "../App";
import { AxiosError } from "axios";

export function Authorization() {
    useEffect(() => {
        document.title = "Авторизация";
    }, []);
    const navigate = useNavigate();

    const store = useContext(Context);
    const [email, setEmail] = useState<string>('');
    const [enterCode, setEnterCode] = useState<boolean>(false);
    const [password, setPassword] = useState<number | undefined>(undefined);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);

    const handleCodeChange = (newPassword: string) => {
        setPassword(parseInt(newPassword));
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" && enterCode === false) {
            e.preventDefault(); // Предотвращаем отправку формы
            handleContinue(); // Вызываем функцию onClick
          }
    }
    const handleContinue = async () => {
        // Проверка на корректность email
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setEmailError("Некорректный email");
            return;
        }
        
        try {
            await AuthService.registration(email);
            setEnterCode(true);
            setEmailError(null); // Сбрасываем ошибку
        } catch (err: any) {
            if (err.response && err.response.status === 400) {
                setEnterCode(true);
                setPasswordError(err.response.data.detail.email);
            }
            // Rest of the error handling code
            else{
                setEmailError("Ошибка при регистрации. Попробуйте снова."); // Обработка ошибки
            }
        }
    };

    // console.log(passwordError);
    const handleLogin = async () => {
        if (password !== undefined && email) {
            // console.log("Попытка входа с email:", email, "и паролем:", password);
            try {
                await store?.login(email, password);
                navigate("/vacancy");
            } catch (e: any) {
                // console.log("Ошибка во время входа:", e); // Логируем ошибку
                if (e.response) {
                    switch (e.response.status) {
                        case 400:
                            setPasswordError("Неверный пароль. Проверьте пароль и повторите попытку.");
                            break;
                        case 401:
                            setPasswordError("Неверный email или код.");
                            break;
                        case 500:
                            setPasswordError("Ошибка сервера. Попробуйте позже.");
                            break;
                        default:
                            setPasswordError("Произошла ошибка. Попробуйте снова.");
                    }
                } else {
                    setPasswordError("Не удалось связаться с сервером.");
                }
            }
        } else {
            setPasswordError("Пожалуйста, введите корректный email и код.");
        }
    };

    const handleChangeEmailClick = () => {
        setEmailError(null);
        setEnterCode(false);
        setPasswordError(null);
    };
    
    return (
        <div className="vh-100 vw-100">
            <div className="d-flex col-md-12 justify-content-center align-items-center vh-100 flex-wrap vw-100">
                <div className="d-flex col h-100 justify-content-center align-items-center d-none d-lg-flex">
                    <div style={{ width: "60%", maxWidth: "100%" }}>
                        <div className="mb-4 ps-0 pe-0 me-1  d-none d-lg-flex">
                            <div className="mb-5 d-flex align-items-start ">
                                <Link to="/vacancy">
                                    <img className="w-75" src="/images/logo.png" alt="Логотип" />
                                </Link>
                            </div>
                        </div>
                        <div className="mb-4 d-lg-none d-lg-flex">
                            <div className="mb-5 d-flex align-items-center ">
                                <Link to="/vacancy">
                                    <img className="w-100" src="/images/logo.png" alt="Логотип" />
                                </Link>
                            </div>
                        </div>

                        <div className="d-flex row justify-content-start align-content-start ps-0 pe-0 ms-1 me-1">
                            {!enterCode ? (
                                <>
                                    <div className="mb-2 ms-0 ps-0">Email</div>
                                    <Form.Control
                                        type="email"
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        onChange={e => {
                                            setEmail(e.target.value);
                                            setEmailError(null); // Сбрасываем ошибку при вводе
                                        }}
                                        placeholder="campus@gmail.com"
                                        value={email}
                                        className="bg-white"
                                    />
                                    {emailError && <div className="text-danger ps-0">{emailError}</div>}
                                    <button
                                        className="btn bg-purple text-white mb-3 w-100 mt-4"
                                        style={{ background: "#4E4FF6" }}
                                        onClick={handleContinue}
                                    >
                                        Продолжить как пользователь
                                    </button>
                                    <div className="d-flex mb-3">
                                        <hr style={{ width: "60%" }} />
                                        <span className="ms-3 me-3">или</span>
                                        <hr style={{ width: "60%" }} />
                                    </div>
                                    <div className="d-flex w-100 justify-content-center">
                                        <Link to="/companyRegistration" className="text-decoration-none w-100">
                                            <button className="btn align-content-center w-100" style={{ background: "#DEE0E2" }}>
                                                Зарегистрировать предприятие
                                            </button>
                                        </Link>
                                    </div>
                                </>
                            ) : (
                                <div className="ps-0 ms-0">
                                    <div className="justify-content-start w-100">
                                        <CodeInput
                                            onCodeChange={handleCodeChange}
                                            email={email}
                                            onClick={handleLogin}
                                            error={passwordError}
                                            onChangeEmailClick={handleChangeEmailClick}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex col h-100 justify-content-center align-items-center d-lg-none">
                    <div style={{ width: "85%", maxWidth: "100%" }}>
                        <div className="mb-4 d-none d-lg-flex">
                            <div className="mb-5 d-flex align-items-start ">
                                <Link to="/vacancy">
                                    <img className="w-75" src="/images/logo.png" alt="Логотип" />
                                </Link>
                            </div>
                        </div>
                        <div className="mb-4 d-lg-none d-lg-flex">
                            <div className="mb-5 d-flex align-items-center ">
                                <Link to="/vacancy">
                                    <img className="w-100" src="/images/logo.png" alt="Логотип" />
                                </Link>
                            </div>
                        </div>
                        <div className="d-flex row justify-content-start align-content-start ps-0 pe-0 ms-1 me-1">
                            {!enterCode ? (
                                <>
                                    <div className="mb-2 ms-0 ps-0">Email</div>
                                    <Form.Control
                                        type="text"
                                        onChange={e => {
                                            setEmail(e.target.value);
                                            setEmailError(null); // Сбрасываем ошибку при вводе
                                        }}
                                        placeholder="campus@gmail.com"
                                        value={email}
                                        className="bg-white"
                                    />
                                    {emailError && <div className="text-danger ps-0">{emailError}</div>}
                                    <button
                                        className="btn bg-purple text-white mb-3 w-100 mt-4"
                                        style={{ background: "#4E4FF6" }}
                                        onClick={handleContinue}
                                    >
                                        Продолжить как пользователь
                                    </button>
                                    <div className="d-flex mb-3">
                                        <hr style={{ width: "60%" }} />
                                        <span className="ms-3 me-3">или</span>
                                        <hr style={{ width: "60%" }} />
                                    </div>
                                    <div className="d-flex w-100 justify-content-center">
                                        <Link to="/companyRegistration" className="text-decoration-none w-100">
                                            <button className="btn align-content-center w-100" style={{ background: "#DEE0E2" }}>
                                                Зарегистрировать предприятие
                                            </button>
                                        </Link>
                                    </div>
                                </>
                            ) : (
                            <>
                                <div className="justify-content-start d-none d-lg-flex w-75">
                                    <CodeInput
                                        onCodeChange={handleCodeChange}
                                        email={email}
                                        onClick={handleLogin}
                                        error={passwordError}
                                        onChangeEmailClick={handleChangeEmailClick}
                                    />
                                </div>
                                <div className="justify-content-start d-lg-none w-100">
                                    <CodeInput
                                        onCodeChange={handleCodeChange}
                                        email={email}
                                        onClick={handleLogin}
                                        error={passwordError}
                                        onChangeEmailClick={handleChangeEmailClick}
                                    />
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col d-none d-lg-flex d-flex justify-content-center align-items-center vh-100">
                    <img className="w-75" src="/images/icons/authorization-picture1.png" alt="Изображение авторизации" />
                </div>
            </div>
        </div>
    );
}
