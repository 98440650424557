import { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import { BoxMap, BoxMini } from "../../components/Box";
import { Link, useParams } from "react-router-dom";
import VacancyService from "../../api/services/VacancyService";
import { AvailableVacancy, VacancyBriefInformation } from "../../api/models/response/VacancyResponse";
import { Company } from "../../api/models/Company";
import AuthService from "../../api/services/AuthService";
import VacancyComponentNew from "../../components/VacancyComponentNew";

export function CompanyPage(){
    // useEffect(() => {
    //     document.title = "Моя компания";
    // }, []);

    
    const { id } = useParams<{ id: string }>();
    const companyId = Number(id ?? "0"); // или любое другое значение по умолчанию
    const [company, setCompany] = useState<Company>();

    
    const [vacancys, setVacancys] = useState<VacancyBriefInformation[]>([]);
    const store = useContext(Context);
    
    
    useEffect(() => {
        document.title = company?.name_company ?? "Страница компании" ;
    }, [store]);



    useEffect(() => {
        const fetchVacancys = async() => {
            try{
                const response = await VacancyService.getCompanyVacanciesBriefInfo(companyId,1, 10);
                setVacancys(response.data);
            }
            catch(error){
                // console.error("Ошибка получения вакансий:", error);
            }
        }
        fetchVacancys()


        const fetchCompany = async() => {
            if(companyId === store?.userBriefInformation?.company_id){
                setCompany(store?.company);
                return
            } 
            try{
                const response = await AuthService.getCompanyInfo(companyId);
                setCompany(response.data);
                // console.log(company)
            }
            catch(error){
                // console.error("Ошибка получения информации о компании:", error);
            }
        }
        fetchCompany();
    }, [companyId, store])


    return(
        <div className="container">
            <BoxMini styleClass="bg-white position-relative">
                { store?.userBriefInformation?.company_id === companyId && 
                    <>
                        <div className="d-flex justify-content-end d-md-none">
                            <div className="align-items-center">
                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                <Link className="text-decoration-none fs-7" to="/editCompanyInformation" style={{ color: "blue" }}>Редактровать</Link>
                            </div>
                        </div>
                        <div className="d-none d-md-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                            <div className="align-items-center">
                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                <Link className="text-decoration-none fs-7" to="/editCompanyInformation" style={{ color: "blue" }}>Редактровать</Link>
                            </div>
                        </div>
                    </>}
                    <div className="row ">
                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center mb-3 mb-md-0">
                            <div className="w-50 d-flex justify-content-center align-items-center">
                                {   
                                    company?.avatar ? 
                                    <img 
                                        src={company?.avatar} 
                                        className="rounded-circle img-fluid" 
                                        style={{ maxHeight: "186px", maxWidth: "186px", height: "30vw", width: "30vw", objectFit: "cover" }} 
                                        alt="Company Avatar" 
                                    />
                                    :
                                    <img 
                                        src="/images/icons/company/company-default.png" 
                                        className="rounded-circle img-fluid" 
                                        style={{ maxHeight: "186px", maxWidth: "186px", height: "auto", width: "auto", objectFit: "cover" }} 
                                        alt="Default Company Avatar" 
                                    />
                                }
                            </div>
                            <div className="w-50 d-flex justify-content-center align-items-center ms-2 ms-lg-4 ms-md-4">
                                <div>
                                    <h3 className="fw-bold fs-4">{company?.name_company}</h3>
                                    {company?.social_network_url && (
                                        <div className="mb-3">
                                            <h4>
                                                <a 
                                                    href={company?.social_network_url.replace('localhost:3000', '')} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    style={{ color: '#4640DE', textDecoration: 'none', cursor: 'pointer' }}
                                                >
                                                    {company?.social_network_url}
                                                </a>
                                            </h4>
                                        </div>
                                    )}
                                    <div className="d-flex align-items-center">
                                        <img 
                                            src="/images/icons/company/location.png" 
                                            style={{ width: "24px", height: "24px" }} 
                                            alt="Location Icon" 
                                        />
                                        <div className="ms-2">
                                            <div className="row">
                                                <span className="text-color-gray">Локация</span>
                                            </div>
                                            <div className="row">
                                                <span className="fw-bold">{company?.city?.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                            {company?.about && (
                                <div className="w-100 justify-content-center">
                                    <span className="text-color-gray fs-6">Коротко о компании:</span>
                                    <div>
                                        <span className="fs-6">{company?.about}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>



            </BoxMini>
            <BoxMini styleClass="bg-white position-relative">
                { store?.userBriefInformation?.company_id === companyId && 
                    <>
                        <div className="d-flex justify-content-end d-md-none">
                            <div className="align-items-center">
                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                <Link className="text-decoration-none fs-7" to="/companyVacancys" style={{ color: "blue" }}>Редактровать</Link>
                            </div>
                        </div>
                        <div className="d-none d-md-flex d-flex justify-content-end position-absolute top-0 end-0 p-3 px-lg-4 py-lg-3 ">
                            <div className="align-items-center">
                                <img src="/images/icons/editing.png" style={{ width: "17px", height: "17px" }} alt="Edit" />
                                <Link className="text-decoration-none fs-7" to="/companyVacancys" style={{ color: "blue" }}>Редактровать</Link>
                            </div>
                        </div>
                    </>}
                {/* <div>
                    <h3 className="fw-bold mb-1">Описание компании</h3>
                    <div className="p-2">
                        <p className="m-0">{company?.about}</p>
                    </div>
                </div>
                <hr className="m-2"></hr> */}
                <div className="">
                    <h3 className="fw-bold mb-3">Вакансии компании</h3>
                </div> 
                <div>
                    {vacancys && vacancys.map((vacancy) => (
                        <VacancyComponentNew
                            key={vacancy.id}
                            vacancy={vacancy} // Передаем объект vacancy
                            companyAvatar={false} // Передаем companyAvatar
                        />
                    ))}
                </div>

            </BoxMini>              
        </div>
    )
}