import React, { useEffect } from 'react';
import { Box } from '../components/Box';



export default function AboutUs() {
  useEffect(() => {
    document.title = "Наши контакты";
}, []);

  return (
    <>
    <Box styleClass='bg-white'>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='row text-center'>
          <h1 className="">Информация о нас</h1>
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        <img
          src="/images/logo.png" // Замените на путь к вашему логотипу
          className="img-fluid mb-2"
          style={{ maxWidth: '300px' }}
        />
      </div>

      <div className='d-flex justify-content-center'>
        <h2 className="card-title fw-bold mb-4">ООО "Кампус"</h2>
      </div>

      <div className='d-flex justify-content-center'>
        <h4 className='fw-bold'>
          ИНН:  
        </h4>
        <h4 className='ms-2'>
        0272922611
        </h4>
      </div>

      <div className='d-flex justify-content-center'>
        <h4 className='fw-bold'>
          ОГРН:  
        </h4>
        <h4 className='ms-2'>
        1230200043446
        </h4>
      </div>

      <div className='d-flex justify-content-center'>
        <h4 className='fw-bold'>
          Почта:  
        </h4>
        <h4 
        className="fw-bold text-decoration-underline ms-2 text-black" 
        style={{ cursor: 'pointer' }} 
        onClick={() => {
            navigator.clipboard.writeText('campus.org@yandex.ru');
            alert('Почта скопирована в буфер обмена'); 
        }}>
          campus.org@yandex.ru
        </h4>
      </div>
    </Box>
    </>
  )
}

