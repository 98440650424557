import { useEffect, useState } from "react";

export function MyCandidates(){
    useEffect(() => {
        document.title = "Кандидаты вакансий";
    }, []);

    const [activeButton, setActiveButton] = useState<string>("Неразобранные отклики");
    const handleButtonClick = (buttonIndex: string) => {
      setActiveButton(buttonIndex);
    };

    return(
        <>
            <div className="w-100">
                <h1 className="ms-3 mb-5">Мои кандидаты</h1>

                <div className="d-flex p-3 bg-white bs mb-4">
                    <div className="bg-gray bs p-2 d-flex align-items-center me-3" style={{width:"85%"}}>
                        <img className="me-1"src="/images/icons/search.png"></img>
                        <input className="bg-gray border-0 bs" style={{width:"96%"}} placeholder="Поиск, используйте (), AND, OR, NOT, чтобы уточнить запрос"></input>
                    </div>
                    <div className="d-flex justify-content-end" style={{width:"15%"}}>
                        <button className="btn btn-dark" style={{width:"100%"}}>
                            Найти
                        </button>
                    </div>
                </div> 

                <div className="d-flex justify-content-start align-items-center mb-4">
                    {["Неразобранные отклики", "Кандидаты в работе", "Наймы", "Отказы", "Архив"].map((buttonIndex) => (
                        <button
                        className="btn me-3 p-2 ps-3 pe-3"
                        key={buttonIndex}
                        style={{
                            backgroundColor: activeButton === buttonIndex ? '#205AED' : 'white',
                            color: activeButton === buttonIndex ? 'white' : 'black',
                        }}
                        onClick={() => handleButtonClick(buttonIndex)}
                        >
                        {buttonIndex}
                        </button>
                    ))}
                </div>

                <div className="w-100 row">
                    <div className="w-25">
                        <h5 className="mb-3 mt-2"> Город кандидата </h5>
                        <div className="d-flex p-2 bg-white bs mb-4">
                            <div className="bg-gray bs p-1 d-flex align-items-center w-100">
                                <img className="me-1"src="/images/icons/search.png"></img>
                                <input className="bg-gray border-0 bs w-100" placeholder="Название города"></input>
                            </div>
                        </div> 
                    </div>    
                    <div className="col">
                        <h3 className="mb-3">{activeButton}</h3>
                        <div className="bg-white bs p-3">
                            <div className="d-flex mb-3">
                                <div className="bg-gray bs justify-content-start align-items-center p-2">
                                    <span>Отклик на вакансию Разработчик</span>
                                </div>
                                <div className="col d-flex justify-content-end align-items-center">
                                    <span style={{color:"gray"}}>Сегодня</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex justify-content-lg-center " style={{width:"15%"}}>
                                    <img src="/images/icons/user/user-icon.png" style={{width:"32px", height:"32px"}}></img>
                                </div>
                                <div className="col w-75">
                                    <h4 className="mb-1">Иван</h4>
                                    <div className="mb-1">
                                        <span>Живет в городе Москва, Россия</span>
                                    </div>
                                    <div className="mb-1">
                                        <span>Интересуют:</span>
                                    </div>
                                    <div className="d-flex mb-2">
                                        {["Удаленная работа", "Офис или гибрид Москва"].map((item, index) => (
                                            <div key={index} className="bg-gray bs p-2 me-2">
                                                <span>{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex">
                                        <span>Developer</span>
                                        <span style={{color:"gray"}}>{"(02.2024 - н.в.)"}</span>
                                    </div>
                                    <span style={{color:"gray"}}>Ранее: Pluto, Jupiter</span>
                                    <div className="d-flex mt-2 mb-2">
                                        {["Java", "PHP", "JavaScript", "Scrum"].map((item, index) => (
                                            <div key={index} className="bg-gray bs p-2 me-2">
                                                <span>{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-1">
                                        <span>200 000 ₽/мес на руки</span>
                                    </div>
                                    <div className="">
                                        <span style={{color:"gray"}}>Комфортная зарплата — 250 000 ₽/мес на руки</span>
                                    </div>

                                    <div className="d-flex justify-content-start mt-4 mb-3">
                                        <button 
                                            className="btn btn-dark me-3"> 
                                            Открыть контакты
                                        </button>
                                        <button className="btn btn-outline-dark"> Отказать </button>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center mt-4 mb-3">
                                        <button className="btn-bright large me-2">Здравствуйте</button>
                                        <button className="btn-gray large me-2">
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.4721 1.53786C14.4275 -0.506759 11.1125 -0.506761 9.06786 1.53786L1.53786 9.06786C-0.50676 11.1125 -0.50676 14.4275 1.53786 16.4721C3.58248 18.5167 6.89746 18.5167 8.94207 16.4721L16.4721 8.94206C18.5167 6.89745 18.5167 3.58247 16.4721 1.53786ZM10.4821 2.95207C11.7456 1.6885 13.7943 1.6885 15.0579 2.95207C16.3214 4.21564 16.3214 6.26428 15.0579 7.52785L12 10.5857L7.42418 6.00996L10.4821 2.95207ZM6.00996 7.42418L2.95207 10.4821C1.6885 11.7456 1.6885 13.7943 2.95207 15.0579C4.21564 16.3214 6.26429 16.3214 7.52786 15.0579L10.5857 12L6.00996 7.42418Z" fill="#121221"/>
                                            </svg>
                                            Label
                                        </button>
                                        <button className="btn-violet large me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.4721 4.53786C17.4275 2.49324 14.1125 2.49324 12.0679 4.53786L4.53786 12.0679C2.49324 14.1125 2.49324 17.4275 4.53786 19.4721C6.58248 21.5167 9.89746 21.5167 11.9421 19.4721L19.4721 11.9421C21.5167 9.89745 21.5167 6.58247 19.4721 4.53786ZM13.4821 5.95207C14.7456 4.6885 16.7943 4.6885 18.0579 5.95207C19.3214 7.21564 19.3214 9.26428 18.0579 10.5278L15 13.5857L10.4242 9.00996L13.4821 5.95207ZM9.00996 10.4242L5.95207 13.4821C4.6885 14.7456 4.6885 16.7943 5.95207 18.0579C7.21564 19.3214 9.26429 19.3214 10.5279 18.0579L13.5857 15L9.00996 10.4242Z" fill="white"/>
                                            </svg>
                                        </button>
                                        <button className="btn-bright large me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.4721 4.53786C17.4275 2.49324 14.1125 2.49324 12.0679 4.53786L4.53786 12.0679C2.49324 14.1125 2.49324 17.4275 4.53786 19.4721C6.58248 21.5167 9.89746 21.5167 11.9421 19.4721L19.4721 11.9421C21.5167 9.89745 21.5167 6.58247 19.4721 4.53786ZM13.4821 5.95207C14.7456 4.6885 16.7943 4.6885 18.0579 5.95207C19.3214 7.21564 19.3214 9.26428 18.0579 10.5278L15 13.5857L10.4242 9.00996L13.4821 5.95207ZM9.00996 10.4242L5.95207 13.4821C4.6885 14.7456 4.6885 16.7943 5.95207 18.0579C7.21564 19.3214 9.26429 19.3214 10.5279 18.0579L13.5857 15L9.00996 10.4242Z" fill="#4E4FF6"/>
                                            </svg>
                                            Label
                                        </button>
                                        <button className="btn-violet small me-2">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.4721 4.53786C17.4275 2.49324 14.1125 2.49324 12.0679 4.53786L4.53786 12.0679C2.49324 14.1125 2.49324 17.4275 4.53786 19.4721C6.58248 21.5167 9.89746 21.5167 11.9421 19.4721L19.4721 11.9421C21.5167 9.89745 21.5167 6.58247 19.4721 4.53786ZM13.4821 5.95207C14.7456 4.6885 16.7943 4.6885 18.0579 5.95207C19.3214 7.21564 19.3214 9.26428 18.0579 10.5278L15 13.5857L10.4242 9.00996L13.4821 5.95207ZM9.00996 10.4242L5.95207 13.4821C4.6885 14.7456 4.6885 16.7943 5.95207 18.0579C7.21564 19.3214 9.26429 19.3214 10.5279 18.0579L13.5857 15L9.00996 10.4242Z" fill="white"/>
                                            </svg>
                                            Label
                                        </button>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Default radio
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            Default checked radio
                                        </label>
                                    </div>
                                    <div className="input-group mb-3 mt-3">
                                        <div className="input-group-text" id="basic-addon1">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.7929 13.7929C14.1834 13.4024 14.8166 13.4024 15.2071 13.7929L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L13.7929 15.2071C13.4024 14.8166 13.4024 14.1834 13.7929 13.7929Z" fill="#121221"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z" fill="#121221"/>
                                            </svg>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Введите должность" aria-label="Введите должность" aria-describedby="basic-addon1"/>
                                        <button className="input-group-text btn">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L8.58579 10L6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071L10 11.4142L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L11.4142 10L13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289C13.3166 5.90237 12.6834 5.90237 12.2929 6.29289L10 8.58579L7.70711 6.29289Z" fill="#121221" fill-opacity="0.47"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="input-group mb-3 mt-3">
                                        <input type="text" className="form-control" placeholder="Введите фамилию" aria-label="Введите фамилию" aria-describedby="basic-addon1"/>
                                        <button className="input-group-text btn">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L8.58579 10L6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071L10 11.4142L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L11.4142 10L13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289C13.3166 5.90237 12.6834 5.90237 12.2929 6.29289L10 8.58579L7.70711 6.29289Z" fill="#121221" fill-opacity="0.47"/>
                                            </svg>
                                        </button>
                                    </div>
                                    <div>
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Уфа</option>
                                            <option value="1">Москва</option>
                                            <option value="2">Абу Даби</option>
                                            <option value="3">Малояз</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </>
    )
}