import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FormCheck } from "react-bootstrap";
import { Form } from "react-router-dom";

export function Collections(){
    const [activeButton, setActiveButton] = useState<string>("Все подборки");
    const handleButtonClick = (buttonIndex: string) => {
      setActiveButton(buttonIndex);
    };
    
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const [selectedRussia, setSelectedRussia] = useState<boolean>(false);
    const CheckBoxActiveRussia = () =>{
        setSelectedRussia(!selectedRussia);
    }

    return(
        <>
            <div className="w-100">
                <h1 className="ms-3 mb-5">Подборки</h1>

                <div className="d-flex p-3 bg-white bs mb-4">
                    <div className="bg-gray bs p-2 d-flex align-items-center me-3" style={{width:"85%"}}>
                        <img className="me-1"src="/images/icons/search.png"></img>
                        <input className="bg-gray border-0 bs" style={{width:"96%"}} placeholder="Поиск, используйте (), AND, OR, NOT, чтобы уточнить запрос"></input>
                    </div>
                    <div className="d-flex justify-content-end" style={{width:"15%"}}>
                        <button className="btn btn-dark" style={{width:"100%"}}>
                            Найти
                        </button>
                    </div>
                </div> 

                <div className="d-flex justify-content-start align-items-center flex-wrap mb-4 w-100">
                    {["Все подборки", "Java", "Python", "JavaScript", "Fullstack", "Go", "C++", "C#", "PHP", "Ruby", "DevOps", "IOS", "Android", "QA Auto", "QA Manual"].map((buttonIndex) => (
                        <button
                        className="btn me-3 p-2 ps-3 pe-3 mb-3"
                        key={buttonIndex}
                        style={{
                            backgroundColor: activeButton === buttonIndex ? '#205AED' : 'white',
                            color: activeButton === buttonIndex ? 'white' : 'black',
                        }}
                        onClick={() => handleButtonClick(buttonIndex)}
                        >
                        {buttonIndex}
                        </button>
                    ))}
                </div>

                <div className="w-100 row">
                    <div className="w-25">
                        <h5 className="mb-1 mt-2"> Зарплата, Р/мес на руки </h5>
                        <div className="d-flex p-2 mb-3 row">
                            <input className="bg-gray border-0 bs me-3 h-25" placeholder="от" disabled={!isChecked} style={{width:"47%", background:"#F9F9FA"}}></input>
                            <input className="bg-gray border-0 bs h-25" style={{width:"47%", background:"#F9F9FA"}} disabled={!isChecked} placeholder="до"></input>
                            <FormCheck label="указана зарплата" className="mt-2 ms-2" onChange={handleCheckboxChange}>
                            </FormCheck>
                        </div>

                        <div>
                            <h5 className="mb-0 mt-1">Регион кандидата</h5>
                            <div className="p-2 mb-3 row">
                                {['Россия', 'другие страны'].map((element) => (
                                        element != "Россия" ?
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="flexRadioCountry" value={element} id="flexCheckDefault"/>
                                            <label className="form-check-label" id="flexCheckDefault">
                                                {element}
                                            </label>
                                        </div> 
                                        :
                                        <>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="flexRadioCountry" value={element} id="Россия" onChange={CheckBoxActiveRussia}/>
                                                <label className="form-check-label" id="flexCheckDefault"> {element} </label>
                                            </div>
                                            <div className="ms-3 mb-1">
                                            {selectedRussia && ['Москва', 'Уфа', 'Санкт-Петербург', 'другие города'].map((elementCity) => (
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="flexRadioCity" value={elementCity} id="flexCheckDefault" />
                                                    <label className="form-check-label" id="flexCheckCity"> {elementCity}</label>
                                                </div>
                                            ))}
                                            </div>
                                        </> 
                                ))}
                            </div>
                        </div>
                        
                        <div>
                            <h5 className="mb-3 mt-2">Нахождение в выбранном регионе</h5>
                                {['уже живет', 'может переехать'].map((element) => (
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioOnRegion" value={element} id="flexCheckDefault"/>
                                        <label className="form-check-label" id="flexCheckDefault"> {element} </label>
                                    </div> 
                                ))}
                        </div>

                        <div>
                            <h5 className="mb-3 mt-2">Уровень кандидата</h5>
                                {['Junior', 'Middle', 'Senior', 'Teamlead/Manager'].map((element) => (
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="flexRadioCandidateValue" value={element} id="flexCheckDefault"/>
                                        <label className="form-check-label" id="flexCheckDefault"> {element} </label>
                                    </div> 
                                ))}
                        </div>

                        <div>
                            <h5 className="mb-3 mt-2">Уровень английского</h5>
                                {['A1-Beginner/Elementary', 'A2-Pre-Intermediate', 'B1-Intermediate', 'B2-Upper-Intermediate', 'C1/C2-Advaced/Fluent'].map((element) => (
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioEnglishValue" value={element} id="flexCheckDefault"/>
                                        <label className="form-check-label" id="flexCheckDefault"> {element} </label>
                                    </div> 
                                ))}
                        </div>

                    </div>    
                    
                    <div className="col">
                        <h3 className="mb-3">{activeButton}</h3>
                        <div className="bg-white bs p-3">
                            <div className="d-flex mb-3">
                                <div className="bg-gray bs justify-content-start align-items-center p-2">
                                    <span>Отклик на вакансию Разработчик</span>
                                </div>
                                <div className="col d-flex justify-content-end align-items-center">
                                    <span style={{color:"red"}}>#НОВЫЙ КАНДИДАТ</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="d-flex justify-content-lg-center " style={{width:"15%"}}>
                                    <img src="/images/icons/user/user-icon.png" style={{width:"32px", height:"32px"}}></img>
                                </div>
                                <div className="col w-75">
                                    <h4 className="mb-1">Иван</h4>
                                    <div className="mb-1">
                                        <span>Живет в городе </span>
                                        <span className=" fw-bold">Москва, Россия</span>
                                    </div>
                                    <div className="mb-1">
                                        <span>Интересуют:</span>
                                    </div>
                                    <div className="d-flex mb-2">
                                        {["Удаленная работа", "Офис или гибрид Москва"].map((item, index) => (
                                            <div key={index} className="bg-gray bs p-2 me-2">
                                                <span>{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="d-flex">
                                        <span>Developer</span>
                                        <span style={{color:"gray"}}>{"(02.2024 - н.в.)"}</span>
                                    </div>
                                    <span style={{color:"gray"}}>Ранее: Pluto, Jupiter</span>
                                    <div className="d-flex mt-2 mb-2">
                                        {["Java", "PHP", "JavaScript", "Scrum"].map((item, index) => (
                                            <div key={index} className="bg-gray bs p-2 me-2">
                                                <span>{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mb-1">
                                        <span>200 000 ₽/мес на руки</span>
                                    </div>
                                    <div className="">
                                        <span style={{color:"gray"}}>Комфортная зарплата — 250 000 ₽/мес на руки</span>
                                    </div>

                                    <div className="d-flex justify-content-start mt-4 mb-3">
                                        <button 
                                            className="btn btn-dark me-3"> 
                                            Открыть контакты
                                        </button>
                                        <button className="btn btn-outline-dark"> Отказать </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
        </>
    )
}
