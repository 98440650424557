
import { useEffect } from "react";
import DocumentDisplay from "../../components/DocumentDisplay";

export default function Consent() {
    useEffect(() => {
        document.title = "Политика в отношении обработки персональных данных";
    }, []);
    
    return (    
        <div>
            <DocumentDisplay file="/documents/Политика в отношении обработки персональных данных.pdf" />
        </div>
    )
}