import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../App";
import { Box } from "../../../components/Box";
import CustomSelect from "../../../components/CustomSelect";
import { Form } from "react-bootstrap";
import { EducationPutRequest } from "../../../api/models/Education";
import UniversitySelector from "../../../components/UniversitySelector";


export function EditEducation(){
    useEffect(() => {
        document.title = "Редактирование образования";
    }, []);

    const store = useContext(Context);
    const { id } = useParams<{ id: string }>();
    const educationId = id ?? "0"; // или любое другое значение по умолчанию
    const education = store?.education?.find(edu => edu.id === parseInt(educationId));

    useEffect(() => {
        setBaseUniversityId(education?.university?.id ?? 0);
        setYearOfAdmission(education?.year_of_admission ?? 2011);
        setYearOfGraduation(education?.year_of_graduation ?? 2015);
        setLevelOfEducation(education?.level_of_education ?? "");
        setSpecialty(education?.specialty ?? "");       
    }, []);
    useEffect(() => {
        setRequestError(undefined)
        setError(undefined) 
    }, [educationId])

    const [baseUniversityId, setBaseUniversityId] = useState<number>();
    const [year_of_admission, setYearOfAdmission] = useState<number>();
    const [yearOfGraduation, setYearOfGraduation] = useState<number>();
    const [levelOfEducation, setLevelOfEducation] = useState<string>();
    const [specialty, setSpecialty] = useState<string>();

    const [error, setError] = useState<string | undefined>(undefined);
    const [requestError, setRequestError] = useState<string | undefined>(undefined);

    const optionsLevelOfEducation = [
        { value: 'Бакалавриат', label: 'Бакалавриат' },
        { value: 'Специалитет', label: 'Специалитет' },
        { value: 'Магистратура', label: 'Магистратура' },
        { value: 'Аспирантура', label: 'Аспирантура' },
    ];

    const optionsSpecialty = [
        { value: 'Каратист-окулист', label: 'Каратист-окулист' },
        { value: 'Прикладаная информатика в нефтегазовой отрасли', label: 'Прикладаная информатика' },
        { value: 'Фельдшер-механик-парикмахер', label: 'Фельдшер-механик-парикмахер' },
        { value: 'Тракторист-криптоинвестор', label: 'Тракторист-криптоинвестор' },
    ];

    const handleUniversityName = (selectedValues: string | string[]) => {
        const newName = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
        setBaseUniversityId(parseInt(newName));
    };

    const handleLevelOfEducation = (selectedValues: string | string[]) => {
        const newLevel = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
        setLevelOfEducation(newLevel);
    };

    const handleSpecialty = (selectedValues: string | string[]) => {
        const newSprecialty = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
        setSpecialty(newSprecialty);
    };

    const handleSubmit = async() =>{
        // console.log("submit", baseUniversityId, yearOfGraduation, levelOfEducation, specialty, year_of_admission);
        if (!baseUniversityId || !yearOfGraduation || !levelOfEducation || !specialty || !year_of_admission){
            setError("Пожалуйста, заполните поле.");
            return
        }
        const educationRequest: EducationPutRequest = {
            id: parseInt(educationId),
            base_university_id: baseUniversityId ? baseUniversityId : null,
            year_of_admission: year_of_admission ? year_of_admission : null,
            year_of_graduation: yearOfGraduation ? yearOfGraduation : null,
            level_of_education: levelOfEducation ? levelOfEducation : null,
            specialty: specialty ? specialty : null,
        };
        try{
            await store?.putEditEducation(educationRequest)
            setRequestError(undefined)
            setError(undefined)
        }
        catch(e: any){
            setRequestError(e.detail.error)
        }
    }
    


    return(
        <>
            <Box styleClass="bg-white"> 
                <div className="mb-4">
                    <Link to="/editEducations" className=" text-decoration-none" style={{color:"blue", fontSize:"12px"}}> {"<"} Вернуться без сохранения</Link>
                    <p className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                        Редактирование данных об образовании
                        <hr className="m-0"></hr>
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-3 d-flex align-items-start">
                        <div>
                            <div className="fw-bold">Измените данные об образовании</div>
                            <p style={{fontSize:"12px"}} className="text-color-gray">Данные о месте учебы, направлении и т.д.</p>
                            <span className="text-danger">{requestError}</span>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="d-flex flex-column">
                            <div className="mb-3">
                                <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                    Название организации
                                </span>
                                <UniversitySelector initialUniversity={education?.university ?? undefined} onUniversityChange={setBaseUniversityId} />
                                {!baseUniversityId && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="mb-3 w-100">
                                <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                    Год начала обучения
                                </span>
                                <Form.Control 
                                    type="number" 
                                    onChange={(e) => setYearOfAdmission(parseInt(e.target.value))}
                                    className="bg-white form-control"
                                    value={year_of_admission}/>
                                {!year_of_admission && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="mb-3">
                                <div className="w-100">
                                    <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                        Год окончания обучения
                                    </span>
                                    <Form.Control
                                        type="number" 
                                        onChange={(e) => setYearOfGraduation(parseInt(e.target.value))}
                                        className="bg-white" 
                                        value={yearOfGraduation}/>
                                </div>
                                {!yearOfGraduation && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="mb-3">
                                <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                Уровень образования
                                </span>
                                <CustomSelect createOption={false}  initialSelectedValues={[levelOfEducation ?? '']} isMulti={false} initialOptions={optionsLevelOfEducation} onChange={handleLevelOfEducation}></CustomSelect>    
                                {!levelOfEducation && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="mb-3">
                                <span className="text-color-gray fw-bold" style={{fontSize:"16px"}}>
                                Специальность
                                </span>
                                <input 
                                    type="text" 
                                    className="form-control bg-white" 
                                    placeholder="Введите специальность..." 
                                    aria-label="Search" 
                                    aria-describedby="search"
                                    maxLength={32}
                                    value={specialty}
                                    onChange={(e) => setSpecialty(e.target.value)} 
                                />
                                {/* <CustomSelect createOption={false} initialSelectedValues={[specialty ?? '']} isMulti={false} initialOptions={optionsSpecialty} onChange={handleSpecialty}></CustomSelect>     */}
                                {!specialty && <span className="text-danger">{error}</span>}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button 
                            className="btn-violet large" 
                            onClick={()=>handleSubmit()}> 
                            Сохранить 
                        </button>
                    </div>
                </div>
            </Box>
        </>
    )
};
