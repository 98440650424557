import { Box } from "../components/Box";


export default function NotFoundedPage() {
    return (
        <Box styleClass="bg-white ">
            <div className="d-flex justify-content-center align-items-center" style={{height:"70vh"}}>
                <h2>Такой страницы не существует</h2>
            </div>
        </Box>
    )
}