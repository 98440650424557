import React, { useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;

interface DocumentDisplayProps {
    file: string; // Путь к PDF-файлу
}

const DocumentDisplay: React.FC<DocumentDisplayProps> = ({ file }) => {
    // const [numPages, setNumPages] = useState<number | null>(null);
    // const [pageNumber, setPageNumber] = useState<number>(1);

    // function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    //     setNumPages(numPages);
    // }

    return (
        // <div>
        //     <Document
        //         file={file}
        //         onLoadSuccess={onDocumentLoadSuccess}
        //     >
        //         <Page pageNumber={pageNumber} />
        //     </Document>
        //     <div>
        //         <button
        //             disabled={pageNumber <= 1}
        //             onClick={() => setPageNumber(pageNumber - 1)}
        //         >
        //             Назад
        //         </button>
        //         <span>Страница {pageNumber} из {numPages}</span>
        //         <button
        //             disabled={pageNumber >= (numPages || 1)}
        //             onClick={() => setPageNumber(pageNumber + 1)}
        //         >
        //             Вперёд
        //         </button>
        //     </div>
        // </div>
        <div className="pdf-viewer mb-3" style={{ width: '100%', height: '78vh', overflow: 'hidden' }}>
            <iframe
                src={file}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="PDF Viewer"
            />
        </div>
    );
};

export default DocumentDisplay;
