import React, { useState, useEffect } from 'react';
import AuthService from "../services/AuthService";
import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import { API_URL } from "../http";
import { UserBriefInformation, UserInformation } from "../models/User";
import { Experience, ExperienceRequest, ResumeInformation } from "../models/Resume";
import { useNavigate } from 'react-router-dom';
import { EducationPutRequest, EducationRequest, Education } from '../models/Education';
import { Company } from '../models/Company';

export interface State {
    isAuth: boolean;
    isLoading: boolean;
    userBriefInformation: UserBriefInformation | undefined;
    user: UserInformation | undefined;
    resume: ResumeInformation | undefined;
    education: Education[] | undefined;
    company: Company | undefined;
    login: (email: string, code_auth: number) => Promise<void>;
    logout: () => Promise<void>;
    setLoading: (value: boolean) => void;
    // checkAuth: () => Promise<void>;
    getUserBriefInformation: () => Promise<void>;
    getUserInformation: () => Promise<void>;
    postUserInformation: (first_name: string, second_name: string, date_of_birth: Date | undefined, city_id: number | null, about_me: string | undefined, about_me_tags: string[]) => Promise<void>;
    getResumeInformation: () => Promise<void>;
    postResumeInformation: (name_post: string | undefined, skills: string[] | undefined, work_format: number | undefined, languages: number[] | undefined) => Promise<void>;
    postAddWorkExperience: (experience: ExperienceRequest[]) => Promise<void>;
    postUpdateAvatar : (picture: File) => Promise<void>;
    deleteAvatar : () => Promise<void>;
    putEditWorkExperience: (experience: Experience[]) => Promise<void>;
    deleteWorkExperience: (ids: number[]) => Promise<void>;
    postAddEducation: (education: EducationRequest[]) => Promise<void>;
    putEditEducation: (education: EducationPutRequest) => Promise<void>;
    deleteEducation: (ids: number) => Promise<void>;
    getEducation: () => Promise<void>;
    postCreateCompany: (inn: string, name_company: string, phone: string, email: string, type_company: number) => Promise<void>;
    getCompanyInformation: () => Promise<void>;
    putCompanyInformation: (city_id: number, name_company: string, descripton: string, social_network_url: string) => Promise<void>;
    postUpdateCompanyAvatar: (picture: File) => Promise<void>;
    deleteCompanyAvatar: () => Promise<void>;
  }

export function useStore(): State {
    const navigate = useNavigate();
    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userBriefInformation, setUserBriefInformation] = useState<UserBriefInformation | undefined>(undefined);
    const [user, setUser] = useState<UserInformation | undefined>(undefined);
    const [resume, setResume] = useState<ResumeInformation | undefined>(undefined);
    const [education, setEducation] = useState<Education[] | undefined>(undefined);
    const [company, setCompany] = useState<Company | undefined>(undefined);

    useEffect(() => {
        const authData = localStorage.getItem('token');
        if (!authData) {
            setIsAuth(false);
        } else {
            getUserBriefInformation();
            getUserInformation();
            getResumeInformation();
            getEducation();
            if(userBriefInformation?.company_id !== null){
                getCompanyInformation();
            }
            setIsAuth(true);
        }
    }, [localStorage]);

    const setLoading = (value: boolean) => {
        setIsLoading(value);
    };

    const login = async (email: string, code_auth: number) => {
        try {
            const response = await AuthService.login(email, code_auth);
            setIsAuth(true);
            localStorage.setItem('token', response.data.access_token);
            localStorage.setItem('token2', response.data.refresh_token);
            await getUserBriefInformation();
            await getUserInformation();
            await getResumeInformation();
            await getEducation();
            // navigate("/vacancy");
        } catch (e : any) {
            // console.log("Ошибка во время входа:", JSON.stringify(e.response?.data, null, 2));
            // console.log("Статус ответа:", e.response?.status);
            throw e;
    
            // if (e.response) {
            //     switch (e.response.status) {
            //         case 400:
            //             console.log("Неверный запрос. Проверьте введенные данные.");
            //             break;
            //         case 401:
            //             console.log("Неверный email или код.");
            //             break;
            //         case 500:
            //             console.log("Ошибка сервера. Попробуйте позже.");
            //             break;
            //         default:
            //             console.log("Произошла ошибка. Попробуйте снова.");
            //     }
            // } else {
            //     console.log("Не удалось связаться с сервером.");
            // }
        }
    };
    
    
    const logout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('token2');
        setIsAuth(false);
        navigate("/vacancy");
    };

    // const checkAuth = async () => {
    //     setIsLoading(true);
    //     try {
    //         const refresh_token = localStorage.getItem('token2');
    //         if (refresh_token) {
    //             const response = await axios.post<AuthResponse>(
    //                 `${API_URL}auth/refresh_token`,
    //                 { refresh_token },
    //                 { withCredentials: false }
    //             );
    //             localStorage.setItem('token', response.data.access_token);
    //             localStorage.setItem('token2', response.data.refresh_token);
    //             setIsAuth(true);
    //             await getUserInformation();
    //             await getResumeInformation();
    //         }
    //     } catch (error) {
    //         console.error('Ошибка при проверке авторизации:', error);
    //         navigate("/authorization");
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const getUserBriefInformation = async () => {
        try {
            const response = await AuthService.GetUserInfo();
            setUserBriefInformation(response.data);
            if (response.data.company_id !== null){
                await getCompanyInformation();
            }
            // console.log("user", response.data);
        } catch (e) {
            // console.error("Ошибка получения информации о пользователе:", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getUserInformation = async () => {
        try {
            const response = await AuthService.getUserInformation();
            setUser(response.data);
            // console.log("user", response.data);
        } catch (e) {
            // console.error("Ошибка получения информации о пользователе:", e);
        } finally {
            setIsLoading(false);
        }
    };

    const postUpdateAvatar = async (picture: File) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('picture', picture);
        try {
            await AuthService.PostUpdateAvatar(formData);
        } catch (e) {
            // console.error("Ошибка при отправке информации о пользователе:", e);
            throw e;
        } finally {
            setIsLoading(false);
        }
    }
    
    const deleteAvatar = async () => {
        setIsLoading(true);
        try{
            await AuthService.DeleteAvatar();
            await getUserInformation();
        } catch (e) {
            // console.error("Ошибка при отправке информации о пользователе:", e);
            throw e;
        } finally {
            setIsLoading(false);
        }
    }
    const postUserInformation = async (first_name: string | undefined, second_name: string | undefined, date_of_birth: Date | undefined, city_id: number | null, about_me: string | undefined, about_me_tags: string[]) => {
        setIsLoading(true);
        try {
            const response = await AuthService.postUserInformation(
                first_name,
                second_name,
                date_of_birth,
                city_id,
                about_me,
                about_me_tags
            );
            await getUserInformation();
            navigate(`/profile/${userBriefInformation?.user_id}`);
        } catch (e) {
            // console.error("Ошибка при отправке информации о пользователе:", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getResumeInformation = async () => {
        setIsLoading(true);
        try {
            const response = await AuthService.getResumeInformation();
            setResume(response.data);
        } catch (e) {
            // console.error("Ошибка получения данных о резюме", e);
        } finally {
            setIsLoading(false);
        }
    };

    const postResumeInformation = async (name_post: string | undefined, skills: string[] | undefined, work_format: number | undefined, languages: number[] | undefined) => {
        // setIsLoading(true);
        try {
            await AuthService.postResumeInformation(name_post, skills, work_format, languages);
            await getResumeInformation();
            navigate(`/profile/${userBriefInformation?.user_id}`);
        } catch (e) {
            throw e;
        } finally {
            // setIsLoading(false);
        }
    };

    const postAddWorkExperience = async (experience: ExperienceRequest[]) => {
        setIsLoading(true);
        try {
            const response = await AuthService.postAddWorkExperience(experience);
            await getResumeInformation();
            navigate("/editWorkExperiences");
        } catch (e) {
            // console.error("Ошибка при отправлении данных о резюме", e);
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    const putEditWorkExperience = async (experience: Experience[]) => {
        setIsLoading(true);
        try {
            const response = await AuthService.putEditWorkExperience(experience);
            await getResumeInformation();
            navigate("/editWorkExperiences");
        } catch (e) {
            // console.error("Ошибка при отправлении данных о резюме", e);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteWorkExperience = async (ids: number[]) => {
        setIsLoading(true);
        try {
            const response = await AuthService.deleteWorkExperience(ids);
            await getResumeInformation();
        } catch (e) {
            // console.error("Ошибка при удалении опыта работы", e);
        } finally {
            setIsLoading(false);
        }
    };

    const getEducation = async () => {  
        setIsLoading(true);
        try {
            const response = await AuthService.getEducation();
            setEducation(response.data);
        } catch (e) {
            // console.error("Ошибка при получении данных об образовании", e);
        } finally {
            setIsLoading(false);
        }
    }

    const postAddEducation = async(education: EducationRequest[]) =>{
        setIsLoading(true);
        try {
            const response = await AuthService.postAddEducation(education);
            await getEducation();
            navigate("/editEducations");
        } catch (e) {
            // console.error("Ошибка при добавлении образования", e);
        } finally {
            setIsLoading(false);
        }
    }

    const putEditEducation = async(education: EducationPutRequest) =>{
        setIsLoading(true);
        try {
            const response = await AuthService.putEditEducation(education);
            await getEducation();
            navigate("/editEducations");
        } catch (e) {
            // console.error("Ошибка при добавлении образования", e);
        } finally {
            setIsLoading(false);
        }
    }

    const deleteEducation = async(ids: number) =>{
        setIsLoading(true);
        try {
            const response = await AuthService.deleteEducation(ids);
            await getEducation();
            navigate("/editEducations");
        } catch (e) {
            // console.error("Ошибка при добавлении образования", e);
        } finally {
            setIsLoading(false);
        }
    }

    const getCompanyInformation = async() =>{
        setIsLoading(true);
        try{
            const response = await AuthService.getCompanyInformation();
            setCompany(response.data);
        } catch(e){
            // console.error("Ошибка при получении информации о компании", e);
        } finally{
            setIsLoading(false);
        }
    }

    const postCreateCompany = async(inn: string, name_company: string, phone: string, email: string, type_company: number) =>{
        setIsLoading(true);
        try{
            const response = await AuthService.postCreateCompany(inn, name_company, phone, email, type_company);
            await getCompanyInformation();
            navigate(`/profile/${userBriefInformation?.user_id}`);
        }
        catch(e){
            // console.error("Ошибка при добавлении компании", e);
            throw e;
        }
        finally{
            setIsLoading(false);
        }
    }

    const PostUpdateCompanyAvatar = async(picture: File) =>{
        setIsLoading(true);
        setIsLoading(true);
        const formData = new FormData();
        formData.append('picture', picture);
        try{
            const response = await AuthService.PostUpdateCompanyAvatar(formData);
            // await getCompanyInformation();
            navigate(`/company/${userBriefInformation?.company_id}`);
        }
        catch(e){
            // console.error("Ошибка при добавлении компании", e);
        }
        finally{
            setIsLoading(false);
        }
    }

    const DeleteCompanyAvatar = async() =>{
        setIsLoading(true);
        try{
            const response = await AuthService.DeleteCompanyAvatar();
            await getCompanyInformation();
        }
        catch(e){
            // console.error("Ошибка при добавлении компании", e);
        }
        finally{
            setIsLoading(false);
        }
    }

    const putCompanyInformation = async(city_id: number | null, name_company: string, description: string, social_network_url: string) =>{
        setIsLoading(true);
        try{
            const response = await AuthService.putCompanyInformation(city_id, name_company, description, social_network_url);
            await getCompanyInformation();
            navigate(`/company/${userBriefInformation?.company_id}`);
        } catch(e){
            // console.error("Ошибка при добавлении компании", e);
        } finally{
            setIsLoading(false);
        }
    }

    return {
        isAuth,
        isLoading,
        userBriefInformation,
        user,
        resume,
        education,
        company,
        login,
        logout,
        setLoading,
        // checkAuth,
        getUserBriefInformation,
        getUserInformation,
        postUserInformation,
        postUpdateAvatar,
        deleteAvatar,
        getResumeInformation,
        postResumeInformation,
        postAddWorkExperience,
        putEditWorkExperience,
        deleteWorkExperience,
        getEducation,
        postAddEducation,
        putEditEducation,
        deleteEducation,
        getCompanyInformation,
        postCreateCompany,
        putCompanyInformation,
        postUpdateCompanyAvatar: PostUpdateCompanyAvatar,
        deleteCompanyAvatar: DeleteCompanyAvatar,
    };
};