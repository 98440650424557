import { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../../App";
import { ExperienceRequest } from "../../../api/models/Resume";
import { Box } from "../../../components/Box";
import CustomSelect from "../../../components/CustomSelect";

export function AddWorkExperience() {
    useEffect(() => {
        document.title = "Добавление места работы";
    }, []);
    const store = useContext(Context);

    const optionsCompanyName = [
        { value: 'УфаNet', label: 'УфаNet' },
        { value: 'НИИ Транснефть', label: 'НИИ Транснефть' },
        { value: 'УГНТУ', label: 'УГНТУ' },
        { value: 'Сбербанк', label: 'Сбербанк' },
        { value: 'Газпром', label: 'Газпром' },
        { value: 'Роснефть', label: 'Роснефть' },
        { value: 'Лукойл', label: 'Лукойл' },
        { value: 'Ростелеком', label: 'Ростелеком' },
        { value: 'МТС', label: 'МТС' },
        { value: 'ВТБ', label: 'ВТБ' },
        { value: 'Аэрофлот', label: 'Аэрофлот' },
        { value: 'РЖД', label: 'РЖД' },
        { value: 'Татнефть', label: 'Татнефть' },
        { value: 'Сургутнефтегаз', label: 'Сургутнефтегаз' },
        { value: 'Металлоинвест', label: 'Металлоинвест' },
        { value: 'Норильский никель', label: 'Норильский никель' },
        { value: 'РусГидро', label: 'РусГидро' },
        { value: 'Энергия', label: 'Энергия' },
        { value: 'Интер РАО', label: 'Интер РАО' },
        { value: 'КамАЗ', label: 'КамАЗ' },
        { value: 'АвтоВАЗ', label: 'АвтоВАЗ' },
        { value: 'РТС', label: 'РТС' },
        { value: 'Яндекс', label: 'Яндекс' },
        { value: 'Mail.ru Group', label: 'Mail.ru Group' },
        { value: 'Тинькофф', label: 'Тинькофф' },
        { value: 'КроссТек', label: 'КроссТек' },
        { value: 'Северсталь', label: 'Северсталь' },
        { value: 'АФК Система', label: 'АФК Система' },
        { value: 'Детский мир', label: 'Детский мир' },
        { value: 'Лента', label: 'Лента' },
        { value: 'Почта России', label: 'Почта России' },
        { value: 'РТС', label: 'РТС' },
        { value: 'Эльдорадо', label: 'Эльдорадо' },
        { value: 'Магнит', label: 'Магнит' },
    ];

    const optionsTitlePosition = [
        { value: 'Веб-программист frontend', label: 'Веб-программист frontend' },
        { value: 'Веб-программист backend', label: 'Веб-программист backend' },
        { value: 'DevOps', label: 'DevOps' },
        { value: 'Мобильный разработчик', label: 'Мобильный разработчик' },
        { value: 'UI/UX дизайнер', label: 'UI/UX дизайнер' },
        { value: 'Системный администратор', label: 'Системный администратор' },
        { value: 'Аналитик данных', label: 'Аналитик данных' },
        { value: 'Бизнес-аналитик', label: 'Бизнес-аналитик' },
        { value: 'Тестировщик', label: 'Тестировщик' },
        { value: 'Руководитель проекта', label: 'Руководитель проекта' },
        { value: 'Архитектор ПО', label: 'Архитектор ПО' },
        { value: 'Разработчик игр', label: 'Разработчик игр' },
        { value: 'Инженер по данным', label: 'Инженер по данным' },
        { value: 'Копирайтер', label: 'Копирайтер' },
        { value: 'Маркетолог', label: 'Маркетолог' },
        { value: 'HR-менеджер', label: 'HR-менеджер' },
        { value: 'Финансовый аналитик', label: 'Финансовый аналитик' },
        { value: 'Менеджер по продукту', label: 'Менеджер по продукту' },
        { value: 'Ассистент руководителя', label: 'Ассистент руководителя' },
        { value: 'Инженер-электроник', label: 'Инженер-электроник' },
        { value: 'Инженер-механик', label: 'Инженер-механик' },
        { value: 'Инженер-строитель', label: 'Инженер-строитель' },
        { value: 'Менеджер по продажам', label: 'Менеджер по продажам' },
        { value: 'Креативный директор', label: 'Креативный директор' },
        { value: 'Дизайнер интерьеров', label: 'Дизайнер интерьеров' },
        { value: 'Дизайнер графики', label: 'Дизайнер графики' },
        { value: 'Арт-директор', label: 'Арт-директор' },
        { value: 'Менеджер по контенту', label: 'Менеджер по контенту' },
        { value: 'Контент-менеджер', label: 'Контент-менеджер' },
        { value: 'Креативный копирайтер', label: 'Креативный копирайтер' },
        { value: 'Продюсер', label: 'Продюсер' },
        { value: 'Редактор', label: 'Редактор' },
        { value: 'Сценарист', label: 'Сценарист' },
        { value: 'Фотограф', label: 'Фотограф' },
        { value: 'Видеограф', label: 'Видеограф' },
        { value: 'Модель', label: 'Модель' },
        { value: 'Актер', label: 'Актер' },
        { value: 'Музыкант', label: 'Музыкант' },
        { value: 'Композитор', label: 'Композитор' },
        { value: 'Звукорежиссер', label: 'Звукорежиссер' },
        { value: 'Режиссер', label: 'Режиссер' },
        { value: 'Технический писатель', label: 'Технический писатель' },
        { value: 'Секретарь', label: 'Секретарь' },
        { value: 'Курьер', label: 'Курьер' },
        { value: 'Логист', label: 'Логист' },
        { value: 'Менеджер по закупкам', label: 'Менеджер по закупкам' },
        { value: 'Складской работник', label: 'Складской работник' },
        { value: 'Оператор call-центра', label: 'Оператор call-центра' },
        { value: 'Торговый представитель', label: 'Торговый представитель' },
        { value: 'Бухгалтер', label: 'Бухгалтер' },
        { value: 'Юрист', label: 'Юрист' },
        { value: 'Экономист', label: 'Экономист' },
        { value: 'Специалист по охране труда', label: 'Специалист по охране труда' },
        { value: 'Специалист по экологии', label: 'Специалист по экологии' },
        { value: 'Специалист по качеству', label: 'Специалист по качеству' },
        { value: 'Консультант', label: 'Консультант' },
        { value: 'Тренер', label: 'Тренер' },
        { value: 'Психолог', label: 'Психолог' },
        { value: 'Социальный работник', label: 'Социальный работник' },
        { value: 'Врач', label: 'Врач' },
        { value: 'Медсестра', label: 'Медсестра' },
        { value: 'Фармацевт', label: 'Фармацевт' },
        { value: 'Научный сотрудник', label: 'Научный сотрудник' },
    ];

    const [companyName, setCompanyName] = useState<string>("");
    const handleCompanyName = (selectedValues: string | string[]) => {
        const newName = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
        setCompanyName(newName);
    };

    const [titlePosition, setTitlePosition] = useState<string>("");
    const handleTitlePosition = (selectedValues: string | string[]) => {
        const newName = Array.isArray(selectedValues) ? selectedValues[0] : selectedValues;
        setTitlePosition(newName);
    };

    const [gettingStartDate, setGettingStartDate] = useState<Date | undefined>(new Date());
    const [endWorkDate, setEndWorkDate] = useState<Date | undefined>(new Date());
    const [description, setDescription] = useState<string | undefined>("");
    const [error, setError] = useState<string| undefined>(undefined);
    const [requestError, setRequestError] = useState<string| undefined>(undefined);
    const handleSubmit = async() => {
        if (!companyName || !titlePosition || !gettingStartDate || !endWorkDate || !description){
            setError("Пожалуйста, заполните поле.");
            return
        }

        const experience: ExperienceRequest = {
            company_name: companyName,
            title_position: titlePosition,
            getting_started: (gettingStartDate ?? new Date()).toISOString().split('T')[0],
            end_work: (endWorkDate ?? new Date()).toISOString().split('T')[0],
            description: description || "",
        };
        try{
            store && await store?.postAddWorkExperience([experience]);
        }
        catch(e : any){
            setRequestError(e.response?.data?.message);
        }
        
    };

    return (
        <Box styleClass="bg-white">
            <div className="mb-4">
                <Link to="/editWorkExperiences" className="text-decoration-none" style={{ color: "blue", fontSize: "12px" }}>
                    {"<"} Вернуться без сохранения
                </Link>
                <p className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>
                    Добавление опыта работы
                    <hr className="m-0" />
                </p>
            </div>
            <div className="row">
                <div className="col-md-3 d-flex align-items-start">
                    <div>
                        <div className="fw-bold">Заполните данные об опыте работы</div>
                        <p className="text-color-gray" style={{ fontSize: "12px" }}>Данные о названии компании, должности и т.д.</p>
                        <span className="text-danger">{requestError}</span>
                    </div>
                </div>
                <div className="col-md-9">
                    <div className="d-flex flex-column">
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>Название компании</span>
                            <Form.Control
                                    type="text"
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    placeholder="Введите название компании"
                                    value={companyName}
                                    className="bg-white"
                            />
                            {!companyName && <span className="text-danger">{error}</span>}
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>Должность</span>
                            <Form.Control
                                    type="text"
                                    onChange={(e) => setTitlePosition(e.target.value)}
                                    placeholder="Введите название компании"
                                    value={titlePosition}
                                    className="bg-white"
                            />
                            {!titlePosition && <span className="text-danger">{error}</span>}
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>Начало работы</span>
                            <Form.Control 
                                type="date" 
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setGettingStartDate(value ? new Date(value) : undefined);
                                }}
                                className="bg-white" 
                                value={gettingStartDate ? gettingStartDate.toISOString().split('T')[0] : ''}
                            />
                            {!gettingStartDate && <span className="text-danger">{error}</span>}
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>Конец работы</span>
                            <Form.Control 
                                type="date" 
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setEndWorkDate(value ? new Date(value) : undefined);}}
                                className="bg-white" 
                                value={endWorkDate ? endWorkDate.toISOString().split('T')[0] : ''}
                            />
                            {!endWorkDate && <span className="text-danger">{error}</span>}
                        </div>
                        <div className="mb-3">
                            <span className="text-color-gray fw-bold" style={{ fontSize: "16px" }}>Коротко об опыте</span>
                            <textarea 
                                className="form-control bg-white"
                                id="about_me"
                                rows={4}
                                placeholder="Напишите информацию об опыте работы" 
                                onChange={(e) => setDescription(e.target.value)}
                                value={description} 
                            />
                        </div>
                        {!description && <span className="text-danger">{error}</span>}
                    </div>
                    <div className="d-flex justify-content-end">
                        <button 
                            className="btn-violet large" 
                            onClick={handleSubmit}> 
                            Добавить 
                        </button>
                    </div>
                </div>
            </div>
        </Box>
    );
}
