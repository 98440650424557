import React from 'react';

interface NotificationModalProps {
    message: string;
    onClose: () => void;
    type: string;
}

const NotificationModal: React.FC<NotificationModalProps> = ({ message, onClose, type }) => {
    return (
        <>
            {
                type==="error" &&             
                <div className="error-modal fade-in" style={{...{backgroundColor: '#f3dfe0'}, ...modalStyle}}>
                    <div className="modal-content">
                        <div className="modal-header d-flex align-items-center">
                            <h5 className="modal-title" style={{ color: '#b14a4f' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '24px', height: '24px',marginBottom: '3px' }} className="mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Ошибка добавления
                            </h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            <p className='mb-0'>{message} </p>
                        </div>
                    </div>
                </div>
            }
            {
                type==="notification" &&
                <div className="info-alert fade-in" style={{...{backgroundColor: '#e9f7fe'}, ...modalStyle}}>
                <div className="modal-content">
                    <div className="modal-header d-flex align-items-center">
                        <h5 className="modal-title">Уведомление</h5>
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    <div className="alert-body">
                        <p className='mb-0'>{message}</p>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

const modalStyle: React.CSSProperties = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    border: '2px solid #ccc',
    padding: "15px",
    maxWidth: '500px',
    borderRadius: '0.375rem',
    zIndex: 1050, // Убедитесь, что модальное окно поверх других элементов
    display: 'block', // Показать модальное окно
    

};

export default NotificationModal;
