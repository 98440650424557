import { useContext, useEffect, useState } from "react";
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../App";

export function Page(){
    const store = useContext(Context);
    const pathName = useLocation().pathname;
    const navigate = useNavigate();

    useEffect(()=>{

    }, [store])


    return(
        <div className="d-flex flex-column min-vh-100">
            <Container>
                <Navbar className="bg-header" fixed="top">
                    <Container className="d-flex w-100">
                        <Navbar.Brand className="d-flex justify-content-start align-items-center pt-2">
                            <Link to="/vacancy">
                                {/* <svg className="logo" width="189" height="50" viewBox="0 0 189 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M188.419 18.7266C188.419 21.4453 187.2 23.6406 184.763 25.3125C182.325 26.9688 178.989 27.7969 174.755 27.7969C172.411 27.7969 170.286 27.5781 168.38 27.1406C166.474 26.7031 164.942 26.2188 163.786 25.6875V18.75H164.56C164.981 19.0469 165.466 19.375 166.013 19.7344C166.575 20.0781 167.364 20.4531 168.38 20.8594C169.255 21.2188 170.247 21.5312 171.356 21.7969C172.466 22.0469 173.653 22.1719 174.919 22.1719C176.575 22.1719 177.802 21.9922 178.599 21.6328C179.396 21.2578 179.794 20.7109 179.794 19.9922C179.794 19.3516 179.56 18.8906 179.091 18.6094C178.622 18.3125 177.731 18.0312 176.419 17.7656C175.794 17.625 174.942 17.4766 173.864 17.3203C172.802 17.1484 171.833 16.9453 170.958 16.7109C168.567 16.0859 166.794 15.1172 165.638 13.8047C164.481 12.4766 163.903 10.8281 163.903 8.85938C163.903 6.32812 165.099 4.22656 167.489 2.55469C169.896 0.867188 173.177 0.0234375 177.333 0.0234375C179.302 0.0234375 181.185 0.226562 182.981 0.632812C184.794 1.02344 186.208 1.44531 187.224 1.89844V8.55469H186.497C185.247 7.69531 183.817 7 182.208 6.46875C180.614 5.92188 178.997 5.64844 177.356 5.64844C175.997 5.64844 174.849 5.83594 173.911 6.21094C172.989 6.58594 172.528 7.11719 172.528 7.80469C172.528 8.42969 172.739 8.90625 173.161 9.23438C173.583 9.5625 174.575 9.89062 176.138 10.2188C176.997 10.3906 177.919 10.5625 178.903 10.7344C179.903 10.8906 180.903 11.0938 181.903 11.3438C184.122 11.9219 185.763 12.8359 186.825 14.0859C187.888 15.3203 188.419 16.8672 188.419 18.7266Z" fill="#4E4FF6"/>
                                    <path d="M160.912 27.0703H152.474V24.1641C150.912 25.3516 149.482 26.2578 148.185 26.8828C146.888 27.4922 145.341 27.7969 143.544 27.7969C140.638 27.7969 138.396 26.9609 136.818 25.2891C135.255 23.6172 134.474 21.1484 134.474 17.8828V0.75H142.958V13.8047C142.958 15.1328 142.998 16.2422 143.076 17.1328C143.169 18.0078 143.373 18.7344 143.685 19.3125C143.982 19.8906 144.427 20.3125 145.021 20.5781C145.63 20.8438 146.474 20.9766 147.552 20.9766C148.271 20.9766 149.068 20.8438 149.943 20.5781C150.818 20.3125 151.662 19.9219 152.474 19.4062V0.75H160.912V27.0703Z" fill="#4E4FF6"/>
                                    <path d="M131.951 13.5469C131.951 15.7188 131.615 17.6953 130.943 19.4766C130.287 21.2578 129.42 22.7266 128.342 23.8828C127.217 25.1016 125.943 26.0234 124.521 26.6484C123.1 27.2734 121.576 27.5859 119.951 27.5859C118.451 27.5859 117.178 27.4219 116.131 27.0938C115.084 26.7812 114.014 26.3359 112.92 25.7578V36.7266L108.937 33.0703L104.482 36.7266V0.75H112.92V3.49219C114.217 2.46094 115.537 1.625 116.881 0.984375C118.24 0.34375 119.803 0.0234375 121.568 0.0234375C124.85 0.0234375 127.396 1.22656 129.209 3.63281C131.037 6.03906 131.951 9.34375 131.951 13.5469ZM123.256 13.7109C123.256 11.1484 122.818 9.28906 121.943 8.13281C121.068 6.97656 119.686 6.39844 117.795 6.39844C116.998 6.39844 116.178 6.52344 115.334 6.77344C114.49 7.00781 113.686 7.35156 112.92 7.80469V21.0469C113.482 21.2656 114.084 21.4062 114.725 21.4688C115.365 21.5312 115.998 21.5625 116.623 21.5625C118.857 21.5625 120.521 20.9141 121.615 19.6172C122.709 18.3047 123.256 16.3359 123.256 13.7109Z" fill="#4E4FF6"/>
                                    <path d="M82.7642 8.41406V27.0703H74.2798V13.9219C74.2798 12.6406 74.2485 11.5547 74.186 10.6641C74.1235 9.75781 73.9517 9.02344 73.6704 8.46094C73.3892 7.89844 72.9595 7.49219 72.3813 7.24219C71.8188 6.97656 71.022 6.84375 69.9907 6.84375C69.1626 6.84375 68.3579 7.01562 67.5767 7.35938C66.7954 7.6875 66.0923 8.03906 65.4673 8.41406V27.0703H57.0298V0.75H65.4673V3.65625C66.9204 2.51562 68.311 1.625 69.6392 0.984375C70.9673 0.34375 72.436 0.0234375 74.0454 0.0234375C75.7798 0.0234375 77.311 0.414062 78.6392 1.19531C79.9673 1.96094 81.0063 3.09375 81.7563 4.59375C83.4438 3.17188 85.0845 2.05469 86.6782 1.24219C88.272 0.429688 89.8345 0.0234375 91.3657 0.0234375C94.2095 0.0234375 96.3657 0.875 97.8345 2.57812C99.3188 4.28125 100.061 6.73438 100.061 9.9375V27.0703H91.5767V13.9219C91.5767 12.625 91.5454 11.5312 91.4829 10.6406C91.436 9.75 91.272 9.02344 90.9907 8.46094C90.7251 7.89844 90.3032 7.49219 89.7251 7.24219C89.147 6.97656 88.3345 6.84375 87.2876 6.84375C86.5845 6.84375 85.897 6.96875 85.2251 7.21875C84.5532 7.45312 83.7329 7.85156 82.7642 8.41406Z" fill="#4E4FF6"/>
                                    <path d="M44.2651 20.2031V14.7188C43.1245 14.8125 41.8901 14.9453 40.562 15.1172C39.2339 15.2734 38.2261 15.4609 37.5386 15.6797C36.6948 15.9453 36.0464 16.3359 35.5933 16.8516C35.1558 17.3516 34.937 18.0156 34.937 18.8438C34.937 19.3906 34.9839 19.8359 35.0776 20.1797C35.1714 20.5234 35.4058 20.8516 35.7808 21.1641C36.1401 21.4766 36.5698 21.7109 37.0698 21.8672C37.5698 22.0078 38.3511 22.0781 39.4136 22.0781C40.2573 22.0781 41.1089 21.9062 41.9683 21.5625C42.8433 21.2188 43.6089 20.7656 44.2651 20.2031ZM44.2651 24.2812C43.812 24.625 43.2495 25.0391 42.5776 25.5234C41.9058 26.0078 41.2729 26.3906 40.6792 26.6719C39.8511 27.0469 38.9917 27.3203 38.1011 27.4922C37.2104 27.6797 36.2339 27.7734 35.1714 27.7734C32.6714 27.7734 30.5776 27 28.8901 25.4531C27.2026 23.9062 26.3589 21.9297 26.3589 19.5234C26.3589 17.6016 26.7886 16.0312 27.6479 14.8125C28.5073 13.5938 29.7261 12.6328 31.3042 11.9297C32.8667 11.2266 34.8042 10.7266 37.1167 10.4297C39.4292 10.1328 41.8276 9.91406 44.312 9.77344V9.63281C44.312 8.17969 43.7183 7.17969 42.5308 6.63281C41.3433 6.07031 39.5933 5.78906 37.2808 5.78906C35.8901 5.78906 34.4058 6.03906 32.8276 6.53906C31.2495 7.02344 30.1167 7.39844 29.4292 7.66406H28.6558V1.3125C29.5464 1.07812 30.9917 0.804688 32.9917 0.492188C35.0073 0.164062 37.0229 0 39.0386 0C43.8354 0 47.2964 0.742188 49.4214 2.22656C51.562 3.69531 52.6323 6.00781 52.6323 9.16406V27.0703H44.2651V24.2812Z" fill="#4E4FF6"/>
                                    <path d="M16.1328 27.7734C13.8359 27.7734 11.7344 27.5 9.82812 26.9531C7.9375 26.4062 6.28906 25.5625 4.88281 24.4219C3.49219 23.2812 2.41406 21.8438 1.64844 20.1094C0.882812 18.375 0.5 16.3438 0.5 14.0156C0.5 11.5625 0.90625 9.44531 1.71875 7.66406C2.54688 5.88281 3.69531 4.40625 5.16406 3.23438C6.58594 2.125 8.22656 1.3125 10.0859 0.796875C11.9453 0.28125 13.875 0.0234375 15.875 0.0234375C17.6719 0.0234375 19.3281 0.21875 20.8438 0.609375C22.3594 1 23.7734 1.50781 25.0859 2.13281V9.32812H23.8906C23.5625 9.04688 23.1641 8.71875 22.6953 8.34375C22.2422 7.96875 21.6797 7.60156 21.0078 7.24219C20.3672 6.89844 19.6641 6.61719 18.8984 6.39844C18.1328 6.16406 17.2422 6.04688 16.2266 6.04688C13.9766 6.04688 12.2422 6.76562 11.0234 8.20312C9.82031 9.625 9.21875 11.5625 9.21875 14.0156C9.21875 16.5469 9.83594 18.4688 11.0703 19.7812C12.3203 21.0938 14.0859 21.75 16.3672 21.75C17.4297 21.75 18.3828 21.6328 19.2266 21.3984C20.0859 21.1484 20.7969 20.8594 21.3594 20.5312C21.8906 20.2188 22.3594 19.8906 22.7656 19.5469C23.1719 19.2031 23.5469 18.8672 23.8906 18.5391H25.0859V25.7344C23.7578 26.3594 22.3672 26.8516 20.9141 27.2109C19.4766 27.5859 17.8828 27.7734 16.1328 27.7734Z" fill="#4E4FF6"/>
                                </svg> */}
                                <img src="/images/logo.png" className="logo" style={{ width: "189px", height: "auto" }} alt="Logo"></img>
                            </Link>
                        </Navbar.Brand>

                        <Nav className="d-none d-md-flex mx-auto d-flex align-items-center justify-content-around" style={{ width: '50%' }}>
                            {store?.isAuth && 
                            <>
                                <Link to="/vacancy">
                                    {pathName==="/vacancy" ? 
                                        <img src="/images/icons/home-page.png" style={{ width: "35px", height: "35px" }} alt="Home" />
                                        : 
                                        <img  src="/images/icons/home-disabled.png" style={{ width: "35px", height: "35px" }} alt="HomeDisabled"/>
                                    }
                                </Link>
                            
                                <Link to={`/profile/${store.userBriefInformation?.user_id}`}>
                                    {pathName===`/profile/${store.userBriefInformation?.user_id}` ? 
                                        <img src="/images/icons/user/user-icon-header.png" style={{ width: "35px", height: "35px" }} alt="Profile" />
                                        : 
                                        <img  src="/images/icons/user-disabled.png" style={{ width: "35px", height: "35px" }} alt="ProfileDisabled"/>
                                    }
                                </Link>
                                {store.userBriefInformation?.company_id &&
                                    <Link className="" to="/companyVacancys">
                                        {pathName==="/companyVacancys" ? 
                                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 7H29C31.2091 7 33 8.79086 33 11V31C33 33.2091 31.2091 35 29 35H13C10.7909 35 9 33.2091 9 31V11C9 8.79086 10.7909 7 13 7Z" stroke="#4E4FF6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 7H23V17.024L19 13L15 17.024V7Z" stroke="#4E4FF6" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            : 
                                            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13 7H29C31.2091 7 33 8.79086 33 11V31C33 33.2091 31.2091 35 29 35H13C10.7909 35 9 33.2091 9 31V11C9 8.79086 10.7909 7 13 7Z" stroke="#8D8D95" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M15 7H23V17.024L19 13L15 17.024V7Z" stroke="#8D8D95" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        }
                                    </Link>
                                }
                                {/* <Nav.Link className="me-5" href="/myCandidates">
                                    <span style={{ color: "blue" }}>Мои кандидаты</span>
                                </Nav.Link>
                                <Nav.Link href="/collections">
                                    <span style={{ color: "blue" }}>Коллекции</span>
                                </Nav.Link> */}
                            </>
                            }
                        </Nav>

                        <div className="d-flex align-items-center">
                            {!store?.isAuth ? 
                            <Link to="/authorization" className="btn btn-light">
                                <img src="/images/icons/login.png" style={{ width: "24px", height: "24px" }} alt="Login" />
                                Войти
                            </Link>
                            :
                            <div className="dropdown">
                                <button className="btn dropdown-toggle d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    {
                                        store?.user?.avatar_url ? 
                                        <img className="rounded-circle me-1 d-none d-md-flex" src={store?.user?.avatar_url} style={{ width: "35px", height: "35px", objectFit: "cover" }} alt="User Icon" />
                                        :
                                        <img className="rounded-circle me-1 d-none d-md-flex" src="/images/icons/user/user-default.jpg" style={{ width: "35px", height: "35px", objectFit: "cover" }} alt="User Icon" />
                                    }
                                    <img className="me-1 d-md-none" src="/images/icons/menu.png" style={{ width: "35px" }} alt="User Icon" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end p-3" aria-labelledby="dropdownMenuButton">
                                    <div className="d-md-none">
                                        <li>
                                            <Link className="dropdown-item text-black text-decoration-none d-flex align-items-center" to="/vacancy">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    {pathName==="/vacancy" ? 
                                                        <img  src="/images/icons/home-page.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="Home" />
                                                        : 
                                                        <img src="/images/icons/home-disabled.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="HomeDisabled"/>
                                                    }
                                                    Вакансии
                                                </div>
                                            </Link>
                                        </li>
                                        {store?.isAuth && store.userBriefInformation?.company_id && (
                                            <>
                                                <li>
                                                    <Link className="dropdown-item text-black text-decoration-none d-flex align-items-center" to="/companyVacancys" >
                                                        {pathName==="/companyVacancys" ? 
                                                            <img src="/images/icons/my-vacation.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="myVacation" />
                                                            : 
                                                            <img src="/images/icons/my-vacation-disabled.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="myVacationDisabled"/>
                                                        }
                                                        Мои вакансии
                                                    </Link>
                                                </li>
                                            </>
                                        )}
                                        <hr className="dropdown-divider" />
                                    </div>
                                    <li className="dropdown-item" role="button">
                                        <Link to={`/profile/${store.userBriefInformation?.user_id}`} className="text-decoration-none">
                                            <div className="d-flex align-items-center">
                                                {
                                                    store.user?.avatar_url ? 
                                                    <img className="rounded-circle me-2" src={store.user?.avatar_url} style={{ width: "50px", height: "50px", objectFit: "cover" }} alt="User Icon" />
                                                    :
                                                    <img className="rounded-circle me-2" src="/images/icons/user/user-default.jpg" style={{ width: "50px", height: "50px", objectFit: "cover" }} alt="User Icon" />
                                                }
                                                <div>
                                                    <div className="text-black fw-bold">{store.user?.surname} {store.user?.name}</div>
                                                </div>
                                            </div>
                                        </Link> 
                                    </li>
                                    <li className="dropdown-item" role="button">
                                        <Link to={`/profile/${store.userBriefInformation?.user_id}`} className="text-black text-decoration-none d-flex align-items-center">
                                            <img src="/images/icons/profile.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="Settings Icon" />
                                            Мой профиль
                                        </Link> 
                                    </li>
                                    {store.userBriefInformation?.company_id &&
                                        <li className="dropdown-item" role="button">
                                            <Link to={`/company/` + store.userBriefInformation?.company_id} className="text-black text-decoration-none d-flex align-items-center">
                                                <img src="/images/icons/company.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="Settings Icon" />
                                                Моя компания
                                            </Link> 
                                        </li>
                                    }
                                    {/* <li className="dropdown-item" role="button">
                                        <Link to="/settings" className="text-black text-decoration-none d-flex align-items-center">
                                            <img src="/images/icons/settings.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="Settings Icon" />
                                            Настройки
                                        </Link> 
                                    </li> */}

                                    <li className="dropdown-item" role="button">
                                        <div className="text-black text-decoration-none d-flex align-items-center" onClick={() => { store.logout(); }}>
                                            <img src="/images/icons/logout.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="Logout Icon" />
                                            Выйти
                                        </div>
                                    </li>
                                    { !store.userBriefInformation?.company_id && 
                                        <div>
                                            <hr className="dropdown-divider" />
                                            <li className="dropdown-item" role="button" >
                                                <Link to="/companyRegistration" className="d-flex align-items-center text-decoration-none">
                                                    <img src="/images/icons/register-company.png" style={{ width: "24px", height: "24px", marginRight: "10px" }} alt="Register Company Icon" />
                                                    <span style={{color:"#4E4FF6"}}>Зарегистрировать компанию</span>
                                                </Link>
                                            </li>
                                        </div>
                                    }
                                </ul>
                            </div>
                            }
                        </div>
                    </Container>
                </Navbar>
                <div style={{ marginTop: "95px", minHeight: "100%" }}>
                    <Outlet />
                </div>
            </Container>
            <footer className="bg-header p-3" style={{ bottom: 0, left: 0, right: 0, marginTop: "auto" }}>
                <Container className="">
                    <Container className="">
                        <div className="d-flex flex-wrap justify-content-between align-items-center border-bottom pb-2">
                            <img src="/images/footer_logo.png" style={{ width: "10vw", minWidth: "150px"}}></img>
                            <Link to="/aboutUs">
                                <a className="text-underline text-black" style={{fontSize: "14px"}}>О нас</a>
                            </Link>
                            {/* <p className="m-0" style={{fontSize: "14px"}}>&copy; {new Date().getFullYear()} ООО "Кампус"</p>
                            <p className="m-0" style={{fontSize: "14px"}}>ИНН: 0272922611</p>
                            <p className="m-0" style={{fontSize: "14px"}}>ОГРН: 1230200043446</p>
                            <p className="m-0" style={{fontSize: "14px"}}>
                                почта:  
                                <span 
                                    className="fw-bold text-decoration-underline ms-1" 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => {
                                        navigator.clipboard.writeText('campus.org@yandex.ru');
                                        alert('Почта скопирована в буфер обмена'); 
                                    }}
                                >
                                    campus.org@yandex.ru
                                </span>
                            </p> */}
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center pt-1">
                            <p className="m-0" style={{fontSize: "14px"}}>Используя сервис, вы соглашаетесь с <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => { navigate('/userAgreement'); }}>Пользовательским соглашением</span>, <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy'); }}>Политикой обработки персональных данных</span> и <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => { navigate('/consent'); }}>Согласие на обработку персональных данных, разрешенных для распространения</span>. </p>
                            {/* <p className="m-0" style={{fontSize: "14px"}}>Используя сервис, вы соглашаетесь с <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => { navigate('/userAgreement'); }}>Пользовательским соглашением</span>, <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => { navigate('/privacy'); }}>Политикой обработки персональных данных</span> и <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => { navigate('/consent'); }}>Согласие на обработку персональных данных, разрешенных для распространения</span>. </p> */}
                        </div>
                    </Container>

                </Container>
            </footer>
        </div>
    );
}
